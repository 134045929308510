import { Component } from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {OnlyNumberDirective} from "../../shared/directives/only-number.directive";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-workplace-wellness',
    imports: [
        NgForOf,
        FormsModule,
        NgIf,
        OnlyNumberDirective
    ],
    templateUrl: './workplace-wellness.component.html',
    styleUrl: './workplace-wellness.component.scss'
})
export class WorkplaceWellnessComponent {
  constructor(private sanitizer: DomSanitizer) { }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


    whylist = [
    {
      img: '/assets/images/landing/workplace-wellness/wellness_icon.webp',
      text: 'More than <strong>9 in 10</strong> workers say they <a href="https://www.myshortlister.com/insights/how-can-companies-motivate-employees-with-corporate-social-responsibility" target="_blank">feel more motivated</a> at their job if their leaders support well-being efforts. (<a href="https://www.apa.org/news/press/releases/2016/06/workplace-well-being" target="_blank">APA</a>)',
    },
    {
      img: '/assets/images/landing/workplace-wellness/wellness_icon.webp',
      text: '<strong>70%</strong> of employees enrolled in wellness programs have reported higher job satisfaction than those not enrolled in the companies’ program. (<a href="https://www.aflac.com/business/resources/aflac-workforces-report/default.aspx" target="_blank">Aflac</a>)',
    },
    {
      img: '/assets/images/landing/workplace-wellness/wellness_icon.webp',
      text: 'Employee morale is cited as the most improved (<strong>54%</strong>) metric from implementing wellness plans. (<a href="https://www.hubinternational.com/media-center/press-releases/2017/07/hub-international-releases-findings-of-second-annual-benefits-barometer-study/" target="_blank">Hub International</a>)',
    },
    {
      img: '/assets/images/landing/workplace-wellness/wellness_icon.webp',
      text: '<strong>89%</strong> of workers at companies that support well-being initiatives are more likely to recommend their company as a good place to work. (<a href="https://www.forbes.com/sites/nazbeheshti/2019/01/16/10-timely-statistics-about-the-connection-between-employee-engagement-and-wellness/?sh=71acf3f422a0" target="_blank">Forbes</a>)',
    },
  ]
  imgInfo =[
    {
      img: '/assets/images/landing/workplace-wellness/farm_to_office.webp',
      title: 'Farm to Office',
      text: 'Offer a monthly, quarterly, or annual health benefit delivered straight to your office in the form of fresh, regional produce boxes. We’ll curate the season’s best to pack boxes of joy for employees to take home to their families. No team is too small or large for us to deliver in bulk!',
      type: 'green'
    },
    {
      img: '/assets/images/landing/workplace-wellness/gift_boxes.webp',
      title: 'Gift Boxes',
      text: 'Celebrate special occasions with curated local food gift boxes, ideal for showing appreciation to both your team and valued clients. Or, gift a “comfort box” with local frozen meals and snacks to an employee who is experiencing sickness, new parenthood, or a loss in the family.',
      type: 'blue'
    },
    {
      img: '/assets/images/landing/workplace-wellness/breakroom_benefits.webp',
      title: 'Breakroom Benefits',
      text: 'Let us stock your office with a weekly delivery of fresh fruit. Grab and go fruit options could include bananas, apples, oranges, clementines, and pears, plus seasonal favorites like fresh NC peaches!',
      type: 'pink'
    }
  ]
}
