<section class="page-wrap animated fadeIn">
  <div class="container">
    <div class="title is-center is-full-width ">
      <h2 class="title__main mb-3">Delivery Areas</h2>
    </div>
  </div>

  <div class="position-relative">
    <div class="bg-pattern bg-pattern-2"></div>
    <div class="container">
      <div class="row ">
        <div class="col-xl-7 col-lg-5 col-md-6">
          <div class="image-wrap">
            <div class="img-grid-badge"><span class="icon material-symbols-outlined">local_shipping</span></div>
            <div class="bg-image-wrap d-flex justify-content-start p-5 h-100"
                 style="background-image: url('assets/images/landing/delivery-area/main.webp')" alt="delivery Areas">
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-7 col-md-6">
          <div class="delivery-wrap d-flex flex-column bg-white p-3 bg-opacity-50 rounded-1 align-items-start justify-content-center">
            <h2 class="text-center text-md-start h2-semibold d-block w-100 mb-2">Where We Deliver</h2>
            <ul class="ul-location-tag">
              <li class="" *ngFor="let location of locations" style="color: {{location.color}}; border-color:  {{location.color}};">
                <span>{{location.text}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative">
    <div class="bg-pattern bg-pattern-1"></div>
    <div class="container">
      <hr class="my-5">
      <div class="row mb-5">
        <iframe src="https://www.google.com/maps/d/embed?mid=1Y5sLs9BCjPWRPrZIuyofiw-HcLQmjFc&ehbc=2E312F" width="640" height="480"></iframe>
      </div>
    </div>
  </div>

</section>
