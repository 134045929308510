<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 mb-4">
        <div class="row">
          <div class="col-md-4 mb-2 mb-md-0">
            <img class="footer__logo mb-4 mb-md-0"
              src="../../../assets/images/logo-white.webp?v={{logoVersionNumber()}}" alt="The Produce Box - Logo" />
          </div>
          <div class="col-md-4">
            <div class="footer__title" [routerLink]="['/pages/about-us']">About Us</div>
            <ul class="footer__list mb-4 mb-md-0">
              <li><a [routerLink]="['/pages/community-impact']">Community Impact</a></li>
              <li><a [routerLink]="['/pages/our-membership']">Our Membership</a></li>
              <li><a [routerLink]="['/pages/workplace-wellness']">Workplace Wellness</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <div class="footer__title" [routerLink]="['/pages/how-it-works']">
              How it Works
            </div>
            <ul class="footer__list mb-4 mb-md-0">
              <li>
                <a [routerLink]="['/pages/delivery-areas']">
                  Our Delivery Area
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-10">
            <ul class="footer__title-list">
              <li><a [routerLink]="['/pages/producers']">Producers</a></li>
              <li><a [routerLink]="['/pages/gift-cards']">Gift Cards</a></li>
              <li><a [routerLink]="['/pages/contact-us']">Contact Us</a></li>
              <li><a [routerLink]="['/pages/faq']">FAQ</a></li>
              <li class="social-icons__list">
                <span>Follow us</span>
                <ul class="social-icons">
                  <li><a href="https://www.facebook.com/TheProduceBox" target="_blank" aria-label="facebook link"><i
                        class="fab fa-facebook"></i></a></li>
                  <li><a href="https://www.instagram.com/theproducebox/" target="_blank" aria-label="instagram link"><i
                        class="fab fa-instagram"></i></a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-lg-2">
            <div class="to-top" (click)="scrollToTop()">
              <i class="icon fa-solid fa-chevron-up"></i>
              <div class="text">To top</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-text mt-2 mt-lg-5">
      <span>The Produce Box © 2025</span><span class="d-none d-lg-inline"> | </span>
      <span class="d-block d-lg-inline">Powered by <a class="link" href="https://navego.tech"
          [target]="'_blank'">Navego</a></span>
    </div>
    <div class="bottom-copy">
      The best NC foods, delivered to your door.
    </div>
  </div>
</footer>