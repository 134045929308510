<section class="login-content">

  <div class="login">

    <!-- Header -->
    <header class="animated fadeInDown login-header">
      <a routerLink="/shop" class="float-start">
        <img src="/assets/images/logo.webp?v={{logoVersionNumber()}}" alt="Produce the box">
      </a>
    </header>

    <!-- Form -->
    <div class="container">
      <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6 d-flex flex-column justify-content-center align-items-center login-card">

          <!-- @if (!showTempPasswordResetFlow()) { -->
          <h2 class="title-text h2-bold text-secondary-800 head-font mb-0">
            Welcome!
          </h2>
          <p class="login-content__subtitle mt-1">
            Welcome to our NEW e-commerce experience!
          </p>
          <p class="login-content__subtitle mb-3">
            If you're a returning customer and haven't yet logged into the new site for the first time, click here: <a
              href="#!" class="forget-link login-content__subtitle fw-bold"
              [routerLink]="['/account-recovery/check-email']">
              Access my account now!
            </a>
          </p>
          <!-- } -->

          <form class="d-flex flex-column mt-3 login-form" (ngSubmit)="onSubmit($event)">
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Email address</mat-label>
              <input type="email" #emailCtrl="ngModel" [ngModel]="email()" (ngModelChange)="onEmailChange($event)"
                matInput name="emailInput" required />
            </mat-form-field>
            <mat-error>
              @if ((emailCtrl.hasError('required') || emailCtrl.hasError('email')) && (emailCtrl.dirty ||
              emailCtrl.touched)) {
              {{ emailCtrl.hasError('required') ? 'Email is required' : 'Invalid email format' }}
              }
            </mat-error>

            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Password</mat-label>
              <input type="password" #passwordCtrl="ngModel" [ngModel]="password()"
                (ngModelChange)="onPasswordChange($event)" matInput name="passwordInput" required
                type="{{ hidePassword ? 'password' : 'text' }}" />
              <button mat-button matSuffix type="button" (click)="hidePassword = !hidePassword" class="eye-icon-button"
                [attr.aria-label]="'Toggle ' + (hidePassword ? 'visibility' : 'hide')">
                @if (hidePassword) {
                <span class="material-symbols-outlined text-muted">
                  visibility
                </span>
                } @else {
                <span class="material-symbols-outlined text-muted">
                  visibility_off
                </span>
                }
              </button>
            </mat-form-field>
            <mat-error>
              <small class="form-text">
                <p>Your password must have:</p>
                <div class="password-hint">
                  @for (condition of passwordConditions; track $index) {
                  <div class="hint-list">
                    <span class="hint-icon" [class]="condition.key() ? 'text-success' : 'text-danger'">
                      <i class="fas" [class.fa-circle-check]="condition.key()"
                        [class.fa-circle-xmark]="!condition.key()"></i>
                    </span>
                    {{ condition.text }}
                  </div>
                  }
                </div>
              </small>
            </mat-error>

            <div class="col d-flex justify-content-end">
              <a href="#!" class="forget-link" [routerLink]="['/account-recovery/check-email']">
                Forgot your password?
              </a>
            </div>

            <button type="submit" class="btn btn-secondary head-font mt-4 mb-2"
              [disabled]="!isPasswordValid() || !isEmailValid() || authService.isLoadingLogin()">
              @if (authService.isLoadingLogin()) {
              <i class="fas fa-spinner fa-spin"></i>
              }
              Log in
            </button>

            <div class="row mb-4 align-items-center">
              <div class="col">
                <mat-checkbox [(ngModel)]="hasExtendedSession" name="hasExtendedSession" class="remember-label">
                  Keep me logged in!
                </mat-checkbox>
              </div>
            </div>

          </form>

          <footer class="d-flex gap-2 flex-wrap mt-3 justify-content-center text-secondary">
            <span class="h6-regular">Don't have an account yet?</span>
            <span class="h6-bold cursor-pointer" (click)="goToSignUp()">Sign up now</span>
          </footer>

        </div>
      </div>
    </div>

  </div>

  <!-- image -->
  <img src="/assets/images/signup/background.webp" class="background-img" alt="Signup image">

</section>