<section class="page-wrap animated fadeIn">
  <div class="container">
    <div class="title is-center is-full-width ">
      <h2 class="title__main mb-3">How It Works</h2>
      <p class="mb-5 text-justify">
        Shopping with The Produce Box connects you to a movement that redefines how we nourish
        our communities and support local farmers. In becoming a member and shopping for farm-to-door groceries, you are
        making a powerful commitment to yourself, your health, and a thriving local food system. Together, we’re
        championing farmers, funding fresh produce for neighbors in need, reducing waste with sustainable packaging, and
        building a more connected, cooperative world—all while savoring the freshest food delivered
        straight to your door.
      </p>
    </div>

    <div class="step-vertical-grid">
      <div class="step-img"><img src="/assets/images/landing/how/how-1.webp"
          alt="Our local and regional farm partners" /></div>
      <div class="step-number is-first">
        <div class="number"><span class="material-symbols-outlined">person_check</span></div>
      </div>
      <div class="step-info">
        <div class="count">01</div>
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Become a Member</div>
            <div class="desc">
              Create an account to join our community of local food supporters. Based on
              where you live, you’ll be assigned to one of our delivery days. Enter your
              payment information and you’ll be charged an annual membership fee of $25.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid change-order">
      <div class="step-img"><img src="/assets/images/landing/how/how-2.webp" alt="Customize your box" /></div>
      <div class="step-number">
        <div class="number"><span class="material-symbols-outlined">add_shopping_cart</span></div>
      </div>
      <div class="step-info">
        <div class="count">02</div>
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Set up recurring subscriptions as needed</div>
            <div class="desc">
              Subscribe to your favorite items or one of our curated Produce Boxes to make
              grocery shopping easy, or skip this step and build your box from scratch. If you
              subscribe to a Produce Box, you don't even have to log in and check out to get
              peak-season produce delivered that week. Simply set it and forget it, and let us
              bring you the season's best.
            </div>
            <button type="button" class="btn btn-static btn-secondary cursor-pointer" (click)="goToShop()">This week's
              Farm
              Boxes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid">
      <div class="step-img"><img src="/assets/images/landing/how/how-3.webp" alt="Continue Shopping" /></div>
      <div class="step-number">
        <div class="number"><span class="material-symbols-outlined">event</span></div>
      </div>
      <div class="step-info">
        <div class="count">03</div>
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Shop and customize on the weekend</div>
            <div class="desc">
              The store is open and stocked each Friday morning by 9am with the freshest
              food our region has to offer. Customize and add on to your subscription Produce
              Box, or build your order entirely from scratch! Make sure to make any changes
              and checkout before 9pm on Sunday night when our menu closes.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid change-order">
      <div class="step-img"><img src="/assets/images/landing/how/how-4.webp"
          alt="We deliver your order in recyclable packaging" /></div>
      <div class="step-number is-last">
        <div class="number"><span class="material-symbols-outlined">local_shipping</span></div>
      </div>
      <div class="step-info">
        <div class="count">04</div>
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">We deliver to you</div>
            <div class="desc">
              On Tuesday or Wednesday, your Neighborhood Ambassador hand-delivers
              your order to your doorstep. Rinse and repeat!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-banner has-padding-block-5">
  <div class="container">
    <h2 class="section-title text-center">Your Membership Matters</h2>
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
        <p class="text-center">
          In 2023 alone, we delivered 400,000 pounds of fresh, low-cost produce to local non-profits.
          Become a member today to start shopping and support a food system that is more sustainable,
          fair, and joyful today.
        </p>
        <div class="d-flex justify-content-center">
          <a href="/signup" class="btn btn-static btn-padding-lg btn-secondary">Sign up Now</a>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</section>
<section class="has-padding-block-5 position-relative">
  <div class="bg-pattern bg-pattern-2 is-blue"></div>
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-sm-4">
        <img src="assets/images/landing/how/wellness.png" alt="" class="img-fluid">
      </div>
      <div class="col-sm-7 offset-sm-1 h-100">
        <h1 class="text-secondary-light mb-3">
          Workplace <br>Wellness
        </h1>
        <p>
          Through The Produce Box's Workplace Wellness program, you can support your employees' wellbeing with
          discounted access to local food. Learn more and get your organization on board!
        </p>
        <a href="/pages/workplace-wellness" class="btn btn-static btn-secondary">Learn More!</a>
      </div>
    </div>
  </div>
</section>
