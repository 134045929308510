@if (product()) {
<section class="mt-4 animated fadeInDown">
  <div class="border-bottom border-dark border-opacity-10">
    <div class="preorder-line">
      <div class="preordered-product">
        <div class="preordered-product__img">
          <a [routerLink]="['/shop/'+(product()?.productUrl || '')]">
            <img [src]="product()?.img || 'assets/images/product-placeholder.webp'" class="thumb-img"
              (error)="handleImageError($event)">
          </a>
        </div>
        <div class="preordered-product__left">
          <h6>{{product()?.name}}</h6>
          <p class="text-success-emphasis m-0 fw-medium">
            {{product()?.price | currency}}
          </p>
          <p class="m-0">Quantity:
            <span class="text-success-emphasis fw-medium">
              {{product()?.quantity}}
            </span>
          </p>
        </div>
      </div>
      <div class="px-4">
        <h6>Delivery</h6>
        <p class="m-0 text-success-emphasis">
          From
          <span class="fw-medium">
            {{ product()?.preOrder?._readableStartDeliveryDate?.mmddyyyyFormat}}
          </span>
        </p>
        <p class="m-0 text-success-emphasis">
          to
          <span class="fw-medium">
            {{ product()?.preOrder?._readableEndDeliveryDate?.mmddyyyyFormat}}
          </span>
        </p>
      </div>
      @if (!isMobile()) {
      <div class="px-4">
        <h6>
          Deposit:
          <span class="text-success-emphasis">
            {{product()?.preOrder?.deposit | currency}}
          </span>
        </h6>
        <div [innerHTML]="depositConfig()?.additionalInfo"></div>
      </div>
      }
    </div>
    @if (isMobile()) {
    <small class="fst-italic">
      <b>Deposit:</b>
      <span class="text-success-emphasis">
        {{product()?.preOrder?.deposit | currency}}
      </span>
    </small>
    <div class="h7-regular fst-italic" [innerHTML]="depositConfig()?.additionalInfo"></div>
    }
  </div>
</section>
}
