import { Component, EventEmitter, Input, InputSignal, OnInit, Output, Signal, WritableSignal, computed, inject, input, output, signal } from '@angular/core';
import { AccountService, PaymentMethod } from '../../../shared/account.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ArrayOptionsItem, PaymentDataPayload } from '../../../shared/types/account.types';
import { PaymentMethodService } from '../../../settings/billing/payment-method/payment-method.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { provideNativeDateAdapter, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '../../../shared/constants/date-formtats';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { tap } from 'rxjs';
import { CreditCardService } from '../../../shared/credit-card.service';
import { OnlyNumberDirective } from '../../../shared/directives/only-number.directive';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';
import { ANetPayload, ANetResponse } from '../../../shared/purchase/authorize/authorize.types';
import { AuthorizeService } from '../../../shared/purchase/authorize/authorize.service';
import { MaskDateDirective } from '../../../shared/directives/maskDate.directive';

@Component({
    selector: 'app-payment-method-signup',
    providers: [provideNativeDateAdapter(),
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_AND_YEAR },
        PaymentMethodService
    ],
    imports: [
        FormsModule,
        CommonModule,
        MatFormFieldModule,
        MatStepperModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatSelectModule,
        OnlyNumberDirective,
        EmptyMessageComponent,
        MaskDateDirective
    ],
    templateUrl: './payment-method-signup.component.html',
    styleUrl: './payment-method-signup.component.scss'
})
export class PaymentMethodSignupComponent implements OnInit {

  private paymentMethodService = inject(PaymentMethodService);
  accountService = inject(AccountService);
  private creditCardService = inject(CreditCardService);
  private signalsStoreService = inject(SignalsStoreService);
  #authorizeService = inject(AuthorizeService);

  @Output() formValidityChanged = new EventEmitter<boolean>();
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>()

  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;

  in_selectedMembership: InputSignal<any> = input<any>();

  out_paymentMethod = output<PaymentMethod>();
  out_isValidFormPaymentMethod = output<boolean>();
  isWaitingResponse: any = signal(false)

  paymentMethod: WritableSignal<PaymentMethod> = signal({
    cardCode: '',
    firstName: '',
    lastName: '',
    cardNumber: '',
    email: '',
    expirationDate: '',
  })

  isValidFormPaymentMethod = computed(() => this.validatePaymentMethodForm());
  paymentMethodValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[], touched: boolean }>>();
  isCheckedBillingAddress: WritableSignal<boolean> = signal(false);
  isBillingAddressRequired: Signal<boolean> = computed(() => {
    const checkedBillingAddress = this.isCheckedBillingAddress();
    const selectedMembership = this.in_selectedMembership();

    if (checkedBillingAddress) return true;

    const membershipRequireAddress = selectedMembership?.configuration?.requireAddress ?? true

    return !membershipRequireAddress
  })
  billingAddress: any = {};

  date = new FormControl<any>({ value: '', disabled: false });

  states: WritableSignal<ArrayOptionsItem[]> = signal([]);

  creditCardType: WritableSignal<any> = signal(null);
  creditCardSecurityCodeMaxLen = computed(() => {
    const cctype = this.creditCardType();
    let maxlen = 4;
    if (cctype) maxlen = cctype.securityCodeLen || maxlen;

    return maxlen;
  });

  disclaimer = signal('')

  ngOnInit(): void {
    this.setUpPaymentMethodValidations();
    this.#setUpDisclaimerContent();
    this.accountService.mustFetchStates$.pipe(
      tap(value => value ? this.getStates() : null)
    ).subscribe();

    if (this.signalsStoreService.hasSession())
      this.getStates();
  }

  private validatePaymentMethodForm() {
    const formFilled = !!(
      this.paymentMethod()?.firstName &&
      this.paymentMethod()?.lastName &&
      this.paymentMethod()?.cardCode &&
      this.paymentMethod()?.cardNumber &&
      this.paymentMethod()?.expirationDate &&
      (
        !this.isBillingAddressRequired() ||
        (
          this.isBillingAddressRequired() &&
          this.paymentMethod()?.address &&
          this.paymentMethod()?.city &&
          this.paymentMethod()?.state &&
          this.paymentMethod()?.zipCode
        )
      )
    );

    if (!formFilled) {
      this.out_isValidFormPaymentMethod.emit(false);
      return false;
    }

    let valid = true;
    const keys: string[] = Array.from(this.paymentMethodValidations.keys());
    for (const key of keys) {
      if (!this.paymentMethodValidations.get(key)?.()?.valid) {
        valid = false;
        break;
      }
    }
    this.out_isValidFormPaymentMethod.emit(valid);
    this.formValidityChanged.emit(valid);
    return valid;
  }

  formatCreditCardNumber(event: any) {
    let value = event.target.value;

    if (!value) return;

    let cleaned = value.replace(/\D/g, '');

    cleaned = cleaned.substring(0, 16);

    this.creditCardType.set(this.creditCardService.typeCard(cleaned));

    let formatted = cleaned.replace(/(.{4})/g, '$1 ').trim();

    event.target.value = formatted;
    this.paymentMethod().cardNumber = formatted;
    this.updatePaymentMethod({ cardNumber: cleaned });
  }

  private setUpPaymentMethodValidations() {
    const defaultValue = { valid: true, error: '', validations: ['required'], touched: false };
    this.paymentMethodValidations.set('firstName', signal(defaultValue));
    this.paymentMethodValidations.set('lastName', signal(defaultValue));
    this.paymentMethodValidations.set('expirationDate', signal({ ...defaultValue, validations: [...defaultValue.validations, 'dateFormat'] }));
    this.paymentMethodValidations.set('cardCode', signal({ ...defaultValue, validations: [...defaultValue.validations, 'number', 'exactLength'] }));
    this.paymentMethodValidations.set('cardNumber', signal(defaultValue));
    this.paymentMethodValidations.set('address', signal(defaultValue));
    this.paymentMethodValidations.set('city', signal(defaultValue));
    this.paymentMethodValidations.set('state', signal(defaultValue));
    this.paymentMethodValidations.set('zipCode', signal({ ...defaultValue, validations: [...defaultValue.validations, 'number', 'zipCodeExactLength'] }));
    this.paymentMethodValidations.set('stateCode', signal(defaultValue));
  }

  private tearDownPaymentMethodValidations() {
    const resetValue = { valid: true, error: '', validations: [], touched: false };
    this.paymentMethodValidations.set('address', signal(resetValue));
    this.paymentMethodValidations.set('city', signal(resetValue));
    this.paymentMethodValidations.set('state', signal(resetValue));
    this.paymentMethodValidations.set('zipCode', signal(resetValue));
  }

  validatePaymentMethodField(key: string, value: string | any) {
    if (typeof value !== 'string')
      value = value.target.value;
    const entry = this.paymentMethodValidations.get(key);
    if (!entry) return;
    const data = entry();
    for (const validation of entry().validations) {
      switch (validation) {
        case 'required':
          data.error = !value.trim() ? 'This field is required' : ''
          break;
        case 'number':
          data.error = !(/^\d+$/.test(value)) ? 'Enter a valid security code' : ''
          break;
        case 'exactLength':
          const aux = value.toString();
          data.error = aux.length !== this.creditCardSecurityCodeMaxLen() ? `This field requires ${this.creditCardSecurityCodeMaxLen()} digits` : '';
          break;
        case 'zipCodeExactLength':
          const auxValue = value.toString();
          data.error = auxValue.length !== 5 ? `This field requires ${5} digits` : '';
          break;
        case 'dateFormat':
          data.error = !/^(0[1-9]|1[0-2])\/(\d{4}|\d{4})$/.test(value) ? 'This field must be in the format MM/YYYY.' : '';
          break;
        default:
          break;
      }
    }

    this.paymentMethodValidations.set(key, signal({ valid: !data.error, error: data.error, validations: data.validations, touched: true }));
  }


  updatePaymentMethod(newValue: any) {
    this.paymentMethod.update(value => ({ ...value, ...newValue }));
    this.out_paymentMethod.emit(this.paymentMethod());

    // Validate form fields:
    const key = Object.keys(newValue)[0];
    const value = newValue[key];
    this.validatePaymentMethodField(key, value);
  }

  billingAddressToggleChangeHandler(checked: boolean) {
    if (checked) {
      this.getStates()
      this.setUpPaymentMethodValidations()
    } else {
      this.tearDownPaymentMethodValidations()

      const updatedPaymentMethod = {
        ...this.paymentMethod(),
        address: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        additionalAddress: undefined
      };
      this.paymentMethod.set(updatedPaymentMethod);
      this.out_paymentMethod.emit(this.paymentMethod());
    }
  }

  private getStates() {
    if (!this.states().length) {
      this.paymentMethodService.getStates().subscribe(states => this.states.set(states));
    }
  }

  sendDataToANet() {
    const value = this.paymentMethod();
    const [month, year] = value.expirationDate.split('/');
    const ANetData: ANetPayload = {
      cardCode: value.cardCode,
      cardNumber: value.cardNumber,
      year,
      month
    };
    this.#authorizeService.sendPaymentDataToAnet(ANetData, (response: ANetResponse) => this.addPaymentMethod(response))
  }

  addPaymentMethod(opaqueData: ANetResponse) {
    const payload = this.setUpPaymentMethodPayload(opaqueData);
    this.accountService.addPaymentMethod(payload, false).pipe(
      tap(() => this.onNextStep.emit(true))
    ).subscribe();
  }

  private setUpPaymentMethodPayload(opaqueData: any): PaymentDataPayload {
    const values = this.paymentMethod();
    const cardNumber = values.cardNumber.replaceAll(' ', '');
    const cardLastNumbers = cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    if (this.isBillingAddressRequired()) {
      this.billingAddress = {
        additional: values.additionalAddress || '',
        street: values.address || '',
        city: values.city || '',
        zip: values.zipCode || '',
        stateCode: values.state || ''
      }
    } else {
      this.billingAddress = {};
    }
    return {
      billingAddress: this.billingAddress,
      card: {
        firstName: values.firstName || '',
        lastName: values.lastName || '',
        expirationDate: values.expirationDate?.split('/').reverse().join('-') || '',
        number: cardLastNumbers,
      },
      opaqueData
    };
  }

  #setUpDisclaimerContent() {
    this.disclaimer.set(`<p class="m-0">
    By completing registration and entering your payment details, you agree to our $25 annual membership fee, charged upon account creation. Learn more about why we charge a membership fee 
    <a href="/pages/our-membership" target="_blanck">here</a>
    </p>`);
  }

}
