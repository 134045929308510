<form class="needs-validation" [class.was-validated]="isValidFormPaymentMethod()">

  <div class="row form-row">
    <div class="col-md-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="ccNumberCtrl" placeholder="Type your Credit Card Number"
          name="ccNumberCtrl"
          [class.is-invalid]="!paymentMethodValidations.get('cardNumber')?.()?.valid && paymentMethodValidations.get('cardNumber')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('cardNumber')?.()?.valid && paymentMethodValidations.get('cardNumber')?.()?.touched"
          (blur)="validatePaymentMethodField('cardNumber', $event)" (input)="formatCreditCardNumber($event)">
        <label for="ccNumberCtrl">Credit Card Number</label>
        <div
          *ngIf="!paymentMethodValidations.get('cardNumber')?.()?.valid && paymentMethodValidations.get('cardNumber')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('cardNumber')?.()?.error }}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input type="text" class="form-control" name="ccFirstName" placeholder="Type your first name"
          [ngModel]="paymentMethod().firstName"
          [class.is-invalid]="!paymentMethodValidations.get('firstName')?.()?.valid && paymentMethodValidations.get('firstName')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('firstName')?.()?.valid && paymentMethodValidations.get('firstName')?.()?.touched"
          (blur)="validatePaymentMethodField('firstName', $event)"
          (ngModelChange)="updatePaymentMethod({firstName: $event })">
        <label for="ccFirstName">First Name</label>
        <div
          *ngIf="!paymentMethodValidations.get('firstName')?.()?.valid && paymentMethodValidations.get('firstName')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('firstName')?.()?.error }}
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input type="text" class="form-control" name="ccLastName" placeholder="Type your last name"
          [ngModel]="paymentMethod().lastName"
          [class.is-invalid]="!paymentMethodValidations.get('lastName')?.()?.valid && paymentMethodValidations.get('lastName')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('lastName')?.()?.valid && paymentMethodValidations.get('lastName')?.()?.touched"
          (blur)="validatePaymentMethodField('lastName', $event)"
          (ngModelChange)="updatePaymentMethod({lastName: $event })">
        <label for="ccLastName">Last Name</label>
        <div
          *ngIf="!paymentMethodValidations.get('lastName')?.()?.valid && paymentMethodValidations.get('lastName')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('lastName')?.()?.error }}
        </div>
      </div>
    </div>
  </div>
  <div class="row form-row">
    <div class="col-md-6">
      <div class="form-floating">
        <input type="text" class="form-control" name="ccExpirationDate" placeholder
          [ngModel]="paymentMethod().expirationDate"
          [class.is-invalid]="!paymentMethodValidations.get('expirationDate')?.()?.valid && paymentMethodValidations.get('expirationDate')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('expirationDate')?.()?.valid && paymentMethodValidations.get('expirationDate')?.()?.touched"
          (blur)="validatePaymentMethodField('expirationDate', $event)"
          (ngModelChange)="updatePaymentMethod({expirationDate: $event })" maxlength="7" appMaskDate>
        <label for="ccExpirationDate">Expiration Date (MM/YYYY)</label>
        <div
          *ngIf="!paymentMethodValidations.get('expirationDate')?.()?.valid && paymentMethodValidations.get('expirationDate')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('expirationDate')?.()?.error }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input type="password" appOnlyNumber class="form-control" id="ccCVVCtrl" placeholder="Type your CVC"
          [maxlength]="creditCardSecurityCodeMaxLen()" name="ccCVVCtrl" [ngModel]="paymentMethod().cardCode"
          [class.is-invalid]="!paymentMethodValidations.get('cardCode')?.()?.valid && paymentMethodValidations.get('cardCode')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('cardCode')?.()?.valid && paymentMethodValidations.get('cardCode')?.()?.touched"
          (blur)="validatePaymentMethodField('cardCode', $event)"
          (ngModelChange)="updatePaymentMethod({cardCode: $event })">
        <label for="ccCvvCtrl">Security Code</label>
        <div
          *ngIf="!paymentMethodValidations.get('cardCode')?.()?.valid && paymentMethodValidations.get('cardCode')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('cardCode')?.()?.error }}
        </div>
      </div>
    </div>
  </div>

  @if(in_selectedMembership()?.configuration?.requireAddress) {
  <div class="mb-3">
    <mat-slide-toggle [(ngModel)]="isCheckedBillingAddress" (change)="billingAddressToggleChangeHandler($event.checked)"
      name="isCheckedBillingAddress"> My delivery address is different than my billing
      address
    </mat-slide-toggle>
  </div>
  } @else {
  <div class="mb-3">
    <p class="signup__sub-head-text">Billing Address Information</p>
  </div>
  }
  @if (isBillingAddressRequired()) {
  <div class="row form-row animated fadeInDown">
    <div class="col-md-6">
      <div class="form-floating ">
        <input type="text" class="form-control" id="addressCtrl2" name="addressCtrl2" placeholder="Type your Address"
          [ngModel]="paymentMethod().address"
          [class.is-invalid]="!paymentMethodValidations.get('address')?.()?.valid && paymentMethodValidations.get('address')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('address')?.()?.valid && paymentMethodValidations.get('address')?.()?.touched"
          (blur)="validatePaymentMethodField('address', $event)"
          (ngModelChange)="updatePaymentMethod({address: $event })">
        <label for="addressCtrl2">Address</label>
        <div
          *ngIf="!paymentMethodValidations.get('address')?.()?.valid && paymentMethodValidations.get('address')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('address')?.()?.error }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input type="text" class="form-control" id="additionalAddressCtrl2" name="additionalAddressCtrl2"
          placeholder="Type your Additional address informatios" [ngModel]="paymentMethod().additionalAddress"
          (ngModelChange)="updatePaymentMethod({additionalAddress: $event })">
        <label for="additionalAddressCtrl2">Additional address information</label>
      </div>
    </div>
  </div>
  <div class="row form-row animated fadeInDown">
    <div class="col-md-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="cityCtrl2" name="cityCtrl2" placeholder="Type your City"
          [ngModel]="paymentMethod().city"
          [class.is-invalid]="!paymentMethodValidations.get('city')?.()?.valid && paymentMethodValidations.get('city')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('city')?.()?.valid && paymentMethodValidations.get('city')?.()?.touched"
          (blur)="validatePaymentMethodField('city', $event)" (ngModelChange)="updatePaymentMethod({city: $event })">
        <label for="cityCtrl2">City</label>
        <div
          *ngIf="!paymentMethodValidations.get('city')?.()?.valid && paymentMethodValidations.get('city')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('city')?.()?.error }}
        </div>
      </div>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <mat-label>State</mat-label>
        <mat-select name="stateCtrl2"
          [class.is-invalid]="!paymentMethodValidations.get('state')?.()?.valid && paymentMethodValidations.get('state')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('state')?.()?.valid && paymentMethodValidations.get('state')?.()?.touched"
          (selectionChange)="updatePaymentMethod({state: $event.value})">
          @for (item of states(); track $index) {
          <mat-option [value]="item.id">{{ item.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="!paymentMethodValidations.get('state')?.()?.valid && paymentMethodValidations.get('state')?.()?.touched"
        class="invalid-feedback">
        <i class="fas fa-xmark"></i>
        {{ paymentMethodValidations.get('state')?.()?.error }}
      </div>
    </div>
    <div class="col">
      <div class="form-floating">
        <input type="text" class="form-control" id="zipCodeCtrl2" name="zipCodeCtrl2" placeholder="Type your ZIP Code"
          appOnlyNumber [maxlength]="5" [ngModel]="paymentMethod().zipCode"
          [class.is-invalid]="!paymentMethodValidations.get('zipCode')?.()?.valid && paymentMethodValidations.get('zipCode')?.()?.touched"
          [class.is-valid]="paymentMethodValidations.get('zipCode')?.()?.valid && paymentMethodValidations.get('zipCode')?.()?.touched"
          (blur)="validatePaymentMethodField('zipCode', $event)"
          (ngModelChange)="updatePaymentMethod({zipCode: $event })">
        <label for="zipCodeCtrl2">ZIP Code</label>
        <div
          *ngIf="!paymentMethodValidations.get('zipCode')?.()?.valid && paymentMethodValidations.get('zipCode')?.()?.touched"
          class="invalid-feedback">
          <i class="fas fa-xmark"></i>
          {{ paymentMethodValidations.get('zipCode')?.()?.error }}
        </div>
      </div>
    </div>
  </div>
  }

  <app-empty-message [isHtml]="true" [message]="disclaimer()" [width100]="true"></app-empty-message>

  <div class="form-row-action">
    <div class="d-grid gap-2 mt-4 col-md-2 col-12 w-50 mx-auto">
      <button type="button" class="btn btn-secondary head-font" (click)="sendDataToANet()"
        [disabled]="isWaitingResponse() || !isValidFormPaymentMethod()">
        Complete Registration
      </button>
    </div>
  </div>
</form>