import { Component, inject, signal } from '@angular/core';
import { ProductsService } from '../../../product/products.service';
import { RouterModule } from '@angular/router';
import { tap } from 'rxjs';
import { Product } from '../../../product/product.types';
import { LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { Location } from '@angular/common';
import { ProductCardV2Component } from '../../../shared/product-card-v2/product-card-v2.component';

@Component({
    selector: 'app-welcome',
    imports: [
        RouterModule,
        ProductCardV2Component
    ],
    templateUrl: './welcome.component.html',
    styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {

  //#region Services

  #productsService = inject(ProductsService);
  #localStorageService = inject(LocalStorageService);
  #location = inject(Location);

  //#endregion

  //#region Properties

  products = signal<Product[]>([]);

  //#endregion

  //#region Constructors

  constructor() {
    this.getSignupProducts();
  }

  //#endregion

  //#region Methods

  getSignupProducts() {

    this.#productsService
      .getSignupProducts()
      .pipe(
        tap(response => this.products.set(response.data))
      )
      .subscribe();
  }

  goToShop() {
    this.#localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
    this.#location.replaceState('/shop');
    window.location.reload();
  }

  //#endregion
}
