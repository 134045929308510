<div class="container-fluid p-0 custom-box-height">

  @defer (on immediate; when !isLoadingContent) {
  @if (!isLoadingContent) {
  <!-- Breadcrumbs: -->
  @if (!isLimitedUser()) {
  <div class="row pt-4 m-0 px-0 px-sm-4">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          @if (!isCustomBoxSignupFlow()) {
          <li class="breadcrumb-item">
            <a>
              <span class="material-symbols-outlined text-secondary-700" [routerLink]="'/shop'">home</span>
            </a>
          </li>
          <li class="breadcrumb-item text-secondary-700" (click)="gotoCategory(product()?.category?.name)">
            {{ product()?.category?.name }}
          </li>
          } @else {
          <li class="breadcrumb-item text-secondary-700" [routerLink]="'/signup'">
            Go Back
          </li>
          }
          <li class="breadcrumb-item active text-secondary-700" aria-current="page">
            <span class="fw-bold">{{ product()?.name }}</span>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  }
  @if(isMobile()) {
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#your-box" type="button" role="tab">
        Your Box
      </button>
    </li>
    @if(addableItems().size){
    <li class="nav-item">
      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#add-ons" type="button" role="tab">
        Add-ons
      </button>
    </li>
    }
    @if(premiumAddableItems().size) {
    <li class="nav-item">
      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#premium-add-ons" type="button" role="tab">
        Premium Add-ons
      </button>
    </li>
    }
  </ul>
  }

  <div [class.tab-content]="isMobile()" class="main-section">

    <!-- Header -->
    <header [ngClass]="isMobile()  ? 'tab-pane fade show active' : 'row mb-5'" id="your-box" role="tabpanel">

      @if (!isMobile()) {
      <div class="col-sm-12 col-md-3 col-lg-3">
        <img [src]="product().img ?? 'assets/images/product-placeholder.webp'" alt="Image of {{product.name}}"
          class="main-section__image">
      </div>
      }

      <div class="col-sm-12 col-md-12 col-lg-9">

        <div class="row">

          <div class="col-sm-12 col-md-7 col-lg-7 mt-2">

            <span class="h7-regular text-secondary-800">
              Your curated box this week
            </span>

            <div class="d-flex justify-content-between flex-wrap mb-2">
              <h3 class="h3-bold head-font text-secondary-400">
                {{product()?.name}}
              </h3>
              <!-- Amount total for normal flow -->
              @if (!isLimitedUser() || product()?.bundle.isLocked) {
              <h3 class="h3-bold head-font text-secondary-400 font-variant-nums">
                {{product().quantity}} x {{ subTotalAmount() | currency }}
              </h3>
              } @else {
              <!-- Items quantity for HOP (Limited) users -->
              <h3 class="h3-bold head-font text-secondary-400">
                <b>{{ addedItemsQuantity() }}</b> items
              </h3>
              }
            </div>

            <!-- SKIPPED ORDER FOR LIMITED USERS -->
            @if (isLimitedUser() && isOrderSkiped()) {
            <div class="alert alert-warning d-flex align-items-center animated fadeIn m-0" role="alert">
              <i class="fa fa-info-circle text-primary me-2"></i>
              <div class="h7-regular">
                You’ve skipped this order. If you’d like to change your mind, just click the button below to remove the
                skip.
              </div>
            </div>
            <div class="row animated fadeIn mt-3">
              <div class="col d-grid">
                <button type="button" class="btn btn-secondary btn-block h6-semibold head-font" (click)="unskipOrder()">
                  Remove Skip
                </button>
              </div>
            </div>
            }

            <!-- Minimum box items -->
            @if (showLimitedMinimumOrder()) {
            <div class="order-info__item is-not-flex animated fadeIn">
              <p class="m-0">Your box minimum order ({{boxMinimumOrder()}})</p>
              <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
                <mat-progress-bar mode="determinate" [value]="boxPercentOfMinLimit()"></mat-progress-bar>
                <small class="text-nowrap">{{ addedItemsQuantity() }} items</small>
              </section>
            </div>
            }

            <!-- Maximum box items -->
            @if (showLimitedMaximumOrder() && !showLimitedMinimumOrder()) {
            <div class="order-info__item is-not-flex animated fadeIn">
              <p class="m-0">Your box maximum order ({{boxMaximumOrder()}})</p>
              <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
                <mat-progress-bar mode="determinate" [value]="boxPercentOfLimit()"></mat-progress-bar>
                <small class="text-nowrap">{{ addedItemsQuantity() }} items</small>
              </section>
            </div>
            }

            <!-- Disregard option -->
            @if (!isLimitedUser() && isAbleToDiscardChanges()) {
            <div class="row">
              <div class="col d-flex justify-content-end">
                <a href="javascript:void(0)" (click)="undoChanges()">
                  <small>
                    Discard Changes
                  </small>
                </a>
              </div>
            </div>
            }

          </div>

          <div class="col-sm-12 col-md-5 col-lg-5 mt-2">
            <!-- Delivery date, delivery address and frequency for HOP and Farmshare (Limited) users -->
            @if (isLimitedUser()) {
            <!-- Delivery date -->
            @defer (on viewport; when signalStoreService.sessionSignal()) {
            <div class="order-info__item animated fadeIn">
              <div class="order-info__item-icon">
                <span class="material-symbols-outlined">local_shipping</span>
              </div>
              <div class="order-info__item-value">
                <div>
                  <h5 class="order-info__item-title h6-bold">
                    {{deliveryInfo()?.deliveryDateText}}
                  </h5>
                  <p class="order-info__item-text h7-regular">
                    Edit before {{deliveryInfo()?.cutoffDateText}}
                  </p>
                </div>
              </div>
            </div>
            } @placeholder {
            <div class="placeholder-glow animated fadeIn">
              <div class="placeholder col-12 py-5 bg-secondary"></div>
            </div>
            }

            <!-- Delivery address -->
            @defer (on viewport; when signalStoreService.sessionSignal()) {
            <div class="order-info__item animated fadeIn pickup-address">
              <div class="order-info__item-icon">
                <span class="material-symbols-outlined">home</span>
              </div>
              <div class="order-info__item-value">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="order-info__item-title h6-bold">
                      {{addressInfo()?.title}}
                    </h5>
                    <p class="order-info__item-text h7-regular">
                      {{ addressInfo()?.text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            } @placeholder {
            <div class="placeholder-glow animated fadeIn">
              <div class="placeholder col-12 py-5 bg-secondary"></div>
            </div>
            }

            <!-- Frequency -->
            <div class="row">
              <div class="col-12">
                <mat-form-field class="d-flex" subscriptSizing="dynamic">
                  <mat-label> Frequency</mat-label>
                  <mat-select [disabled]="!permissions()?.settings?.subscriptions?.allowed"
                    [value]="selectedFrequency()?.value?.id || selectedFrequency()?.id || ''"
                    (selectionChange)="onChangeFrequency( $event)">

                    @for (freq of signalStoreService.frequencies(); track $index) {
                    <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            } @else {
            <!-- Frequency and start date for normal flow -->
            @if (bundleEditionType() === bundleEditionTypes.subscription) {
            <div class="row">
              <div class="col-6">
                <mat-form-field class="d-flex" subscriptSizing="dynamic">
                  <mat-label> Frequency</mat-label>
                  <mat-select [disabled]="lockFrequencyButton"
                    [value]="selectedFrequency()?.value?.id || selectedFrequency()?.id || ''"
                    (selectionChange)="onChangeFrequency( $event)">
                    @for (freq of signalStoreService.frequencies(); track $index) {
                    <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="d-flex" subscriptSizing="dynamic">
                  <mat-label> Start date</mat-label>
                  <mat-select disabled [value]="'option1'">
                    @if (selectedStartDate()) {
                    <mat-option value="option1"> {{ selectedStartDate() || '' }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            }
            }
          </div>

        </div>

        <!-- What is included -->
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-7">
            <span class="h7-regular text-secondary-800">
              What is included:
            </span>
          </div>
        </div>

        <!-- Added items -->
        <div class="row">
          @if (items().size) {
          @for (bundleItem of items().values(); track $index) {
          <div
            class="col-sm-6 col-md-6 {{showCartPreview() && hasSession() ? 'col-lg-6 col-xl-4' : 'col-lg-4 col-xl-3'}} animated fadeIn custom-box mt-4">
            <app-addable-product-card [removable]="true" [product]="bundleItem" [isLimitedUser]="isLimitedUser()"
              [isLockedBundle]="product().bundle.isLocked || false" (removeItem)="onRemoveItem($event)"
              (changeQuantity)="onChangeQuantity($event)">
            </app-addable-product-card>
          </div>
          }
          }
        </div>

      </div>

    </header>

    <!-- Add-ons -->
    @if (addableItems().size) {
    <div [ngClass]="isMobile()  ? 'tab-pane fade' : 'col-md-12 px-4'" id="add-ons" role="tabpanel">
      <div class="d-none d-sm-block px-md-3 px-0">
        <h3 class="h5-semibold text-secondary-800">
          Recommended Add-Ons:
        </h3>
      </div>
      <mat-tab-group mat-align-tabs="start">
        @for (categoryName of addableItems().keys(); track categoryName) {
        @if (addableItems().has(categoryName) && addableItems().get(categoryName)?.size) {
        <mat-tab>

          <ng-template mat-tab-label>
            {{removeCharByRegex(categoryName, '_')}}
          </ng-template>

          <div class="row-addon">
            @for (addableProduct of addableItems().get(categoryName).values(); track addableProduct?.id) {
            <div class="col-addon">
              <app-addable-product-card [product]="addableProduct" [isLimitedUser]="isLimitedUser()"
                [isLockedBundle]="product().bundle.isLocked || false" (addItem)="onAddItem($event)">
              </app-addable-product-card>
            </div>
            }
          </div>

        </mat-tab>
        }
        }
      </mat-tab-group>
    </div>
    }

    <!-- Premium add on -->
    @if (premiumAddableItems().size) {
    <div [ngClass]="isMobile()  ? 'tab-pane fade' : 'col-md-12 px-4'" id="premium-add-ons" role="tabpanel">
      <div class="d-none d-sm-block px-md-3 px-0">
        <h3 class="h5-semibold text-secondary-800">
          Premium Add-Ons:
        </h3>
      </div>
      <mat-tab-group mat-align-tabs="start">
        @for (categoryName of premiumAddableItems().keys(); track categoryName) {
        @if (premiumAddableItems().has(categoryName) && premiumAddableItems().get(categoryName)?.size) {
        <mat-tab [label]="removeCharByRegex(categoryName, '_')">
          <div class="row-addon">
            @for (addableProduct of premiumAddableItems().get(categoryName).values(); track addableProduct?.id) {
            <div class="col-addon">
              <app-addable-product-card [product]="addableProduct" [isLimitedUser]="isLimitedUser()"
                [isLockedBundle]="product().bundle.isLocked || false" (addItem)="onAddItem($event)">
              </app-addable-product-card>
            </div>
            }
          </div>
        </mat-tab>
        }
        }
      </mat-tab-group>
    </div>
    }

    <!-- Footer -->
    <footer [ngClass]="{'d-flex justify-content-center mt-4': !isMobile(), 'custom-box-action-wrap': isMobile()}">
      <button type="button" class="btn btn-secondary h6-semibold head-font px-5" [class.w-100]="isMobile()"
        [disabled]="shouldDisableSaveButton()" (click)="saveAndSubscribe()">
        @if (!isLimitedUser()) {
        @if (bundleEditionType() === bundleEditionTypes.subscription) {
        Save customization
        @if (!product()?.existsInOrder) {
        & subscribe
        }
        } @else {
        @if(product()?.existsInOrder) {
        Save customization
        } @else {
        Add to cart
        }
        }
        } @else {
        Update/Submit Order
        }
      </button>
    </footer>

  </div>
  }
  } @placeholder {
  LOADING...
  }
</div>
