<div class="page-wrap animated fadeIn ">
  <div class="container my-5">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="img-grid">
            <img src="assets/images/landing/workplace-wellness/main_1.webp" alt="" class="img-main"/>
            <img src="assets/images/landing/workplace-wellness/main_2.webp" alt=""  class="img-sub"/>
        </div>
      </div>
      <div class="col-lg-7 col-md-6">
        <div class="ps-5 text-center text-md-start">
          <h1 class="title__special">Workplace Wellness</h1>
          <p class="mb-5">Partner with The Produce Box to support the wellbeing of your team and their families.
            Healthy eating starts with one employee and soon spreads positivity throughout the
            entire workplace, reaching other members of the household, too!</p>
          <a href="#" class="btn btn-static btn-lg btn-primary">Let’s get started!</a>
        </div>
      </div>
    </div>
  </div>
  <section class="section-perk px-3">
    <div class="container p-5">
      <div class="text-center has-animation fadeInLeft">
        <h2 class="title is-tertiary-font">Produce Perks Program</h2>
        <p class="h4-regular">Encourage employee wellness through providing discounted access to The Produce
          Box’s farm-to-table grocery delivery service.</p>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="title is-center is-full-width ">
      <h2 class="title__main mb-5">How It Works</h2>
    </div>
    <div class="row">
      <div class="offset-lg-1 col-lg-10 offset-xl-2  col-xl-8">
        <div class="step-vertical-grid">
          <div class="step-img"><img src="/assets/images/landing/workplace-wellness/how_it_works_1.webp"
                                     alt="Our local and regional farm partners"/></div>
          <div class="step-number is-first">
            <div class="number"><span class="material-symbols-outlined">person_check</span></div>
          </div>
          <div class="step-info">
            <div class="count">01</div>
            <div class="step-content-flex">
              <div class="step-text">
                <div class="title">You</div>
                <div class="desc">sponsor a Produce Perks membership to grant employees discounted access to our online store
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-vertical-grid change-order">
          <div class="step-img"><img src="/assets/images/landing/workplace-wellness/how_it_works_2.webp"
                                     alt="Customize your box"/></div>
          <div class="step-number">
            <div class="number"><span class="material-symbols-outlined">add_shopping_cart</span></div>
          </div>
          <div class="step-info">
            <div class="count">02</div>
            <div class="step-content-flex">
              <div class="step-text">
                <div class="title">Employees</div>
                <div class="desc">enjoy affordable, fresh, locally-sourced foods, delivered straight to their home or your office
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-vertical-grid">
          <div class="step-img"><img src="/assets/images/landing/workplace-wellness/how_it_works_3.webp"
                                     alt="Our local and regional farm partners"/></div>
          <div class="step-number is-last">
            <div class="number"><span class="material-symbols-outlined">local_shipping</span></div>
          </div>
          <div class="step-info">
            <div class="count">03</div>
            <div class="step-content-flex">
              <div class="step-text">
                <div class="title">The Produce Box</div>
                <div class="desc">takes care of logistics and reporting</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="w-75 mx-auto mt-5">
      <div class="title is-center">
        <h2 class="title__main mb-3">Why Workplace Wellness?</h2>
        <p class="mb-5">Healthy, happy employees are more productive, more engaged, call out sick less often, and positively influence the mood of everyone around them. The stats about employer-sponsored wellness initiatives speak for themselves:
        </p>
      </div>
      <div class="row">
        <div class="col-md-6" *ngFor="let item of whylist">
          <div class="wellness-list">
            <img src="{{item.img}}">
            <p [innerHTML]="sanitizeHtml(item.text)"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="my-5  position-relative">
    <div class="bg-pattern bg-pattern-1 is-pink"></div>
    <div class="container">
      <div class="title is-center">
        <h2 class="title__main mb-3">More Ways to Partner</h2>
      </div>
      <div class="row">
        <div *ngFor="let item of imgInfo" class="offset-lg-1 col-lg-10 offset-xl-1  col-xl-8}">
          <div class="img-info is-{{item.type}}">
            <img src="{{item.img}}">
            <div class="info">
              <h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="position-relative contact-section">
    <div class="bg-pattern bg-pattern-2 is-blue"></div>
    <div class="container">
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="text-center text-sm-start">
          <h2 class="main-title">Ready to build a one of a kind program?</h2>
          <p class="h6-regular">Fill out the form below and our team will get in touch.</p>
          </div>
        </div>
        <div class="col-md-6 mt-3 mt-md-0">
          <div class="klaviyo-form-W54XYe"></div>
        </div>
      </div>
    </div>

  </section>
</div>
