import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { OrderService } from '../order.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BundleEditionTypes } from '../types/order.type';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-shop-bundle-list',
    imports: [
        CommonModule
    ],
    templateUrl: './shop-bundle-list.component.html',
    styleUrl: './shop-bundle-list.component.scss'
})
export class ShopBundleListComponent {

  //#region Services

  #signalsStorageService = inject(SignalsStoreService);

  #orderService = inject(OrderService);

  #router = inject(Router);

  //#endregion

  //#region Properties

  frequencies = computed(() => this.#signalsStorageService.frequencies());

  bundles = computed(() => this.#orderService.getBundlesLikeSubscriptions());

  //#endregion

  //#endregion Constructor

  constructor() {

    toObservable(this.bundles)
      .subscribe(bundles => {
        this.#signalsStorageService.numberBundleSubscribed.set(bundles.length);
      });
  }

  //#endregion

  //#region Methods

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  goToCustomBox(id: number) {
    this.#router.navigate(['/shop/custom-box/' + BundleEditionTypes.subscription + '/' + id]);
  }

  //#endregion
}
