<div class="has-padding-block-5 animated fadeIn position-relative">
  <div class="bg-pattern bg-pattern-1 is-pink"></div>
  <div class="bg-pattern bg-pattern-2 is-blue"></div>
  <div class="container">
    <div class="title is-center">
      <h2 class="title__main">Feeding Families</h2>
      <p class="title__sub">The Produce Box is more than a grocery delivery service.</p>
    </div>
    <div class="row gy-3">
      <div class="col-lg-8 col-md-6">
        <img src="assets/images/landing/about/community-impact/crates-unloaded-warehouse.webp" alt="" class="img-fluid mb-5">
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sub-img-wrap">
          <img src="assets/images/landing/about/community-impact/truck-full-produce.webp" class="img-fluid" alt="Convenient home delivery">
        </div>
      </div>
    </div>
    <div class="row gy-3">
      <div class="col-lg-8 col-md-6">
        <p> As a food hub committed to fresh produce access for all, we source, pack, and deliver thousands of fresh food
          boxes a month for our non-profit partners serving food-insecure families across North
          Carolina.</p>
        <p>
          In 2023 alone, we packed over 26,000 low-cost boxes full of fresh fruits and vegetables
          for our partners, and 2024 is on track to blow past this milestone!
          By bridging local farmers and local eaters through innovative partnerships, we create a
          food system that is more resilient, more equitable, more sustainable, and most of all,
          more joyful.</p>
      </div>
      <div class="col-lg-4 col-md-6">

      </div>
    </div>
  </div>

  <section class="section-quote">
    <div class="container">
    <div class="row gy-5">
      <div class="col-lg-4">
        <div class="img-main">
<!--        <img src="http://localhost:4200/assets/images/logo-white.svg" >-->
          <img src="assets/images/landing/about/community-impact/box.webp"  class="img-fluid"/>
        </div>
      </div>
      <div class="col-lg-8 d-flex align-items-center">
        <div class="quote">
          <blockquote><span class="material-symbols-outlined"></span>The delivery of produce boxes has been such a gift to our clients!! Every week the boxes have been full of
            beautiful produce that our families have enjoyed. Our delivery drivers have been so helpful and kind. It has been such a wonderful experience!”</blockquote>
          - Kim Basden, Holly Springs Food Cupboard
        </div>
      </div>
    </div>
    </div>
  </section>

  <section class="section-banner has-padding-block-5">
    <div class="container">
      <div class="how-banner text-center">
        <div class="py-5">
      <h1 class="mb-3">How it works</h1>
      <p class="h4-regular">Are you a non-profit, church, buying group, or organization that could benefit from
      receiving pre-packed, low-cost produce boxes, or produce in bulk? We’d love to chat
      about how The Produce Box can supply your community with fresh, seasonal food from
      our local farms</p>
        </div>
      </div>
    </div>
  </section>
  <br>
  <section class="mb-5 position-relative">
    <div class="container">
      <div class="row d-flex">
        <div class="col-sm-6">
          <h1 class="text-secondary-light mb-3">
            Why our partners love us:
          </h1>

          <ul class="ul-check-list">
            <li><span class="check-icon material-symbols-outlined">check</span>Pre-packed boxes means there’s no need for employees or volunteers to
              assemble bags or boxes - folks can grab and go!</li>
            <li><span class="check-icon material-symbols-outlined">check</span>Discounted access to seasonal produce items from North Carolina farmers like
              mini watermelons, cantaloupes, bi-color corn and more</li>
            <li><span class="check-icon material-symbols-outlined">check</span>Flexible delivery scheduling to accommodate your distribution times</li>
            <li><span class="check-icon material-symbols-outlined">check</span>Opportunities to partner on grant applications</li>
          </ul>
        </div>
        <div class="col-sm-5 offset-sm-1">
          <img src="assets/images/landing/about/community-impact/client-with-box.webp" alt="" class="img-fluid mb-5">
        </div>
      </div>
      <hr>
      <div class="d-md-flex my-5 gap-5 align-items-center text-center text-md-start">
        <h5>Ideally, your bulk box drop includes at least 50 boxes per delivery, but we can work with
        your budget to find a cadence and volume that meets your needs.
        Interested in bringing fresh produce to your community? Reach out here and a member
        of our team will get in touch!</h5>
        <div>
        <a class="btn btn-lg btn-primary" style="white-space: nowrap" [routerLink]="['/pages/contact-us']">Contact Us</a>
        </div>
      </div>
      <hr>
      <div class="mt-5">
        <div class="title is-center is-full-width">
          <h2 class="title__main mb-5">We are humbled to be just one spoke of the wheel in our local food economy, and
            deeply grateful to our partners who make this work possible.</h2>
        </div>
        <div class="logo-wrap">
          <img src="assets/images/landing/about/community-impact/logo/cafn.png" alt="">
          <img src="assets/images/landing/about/community-impact/logo/foodbank.png" alt="">
          <img src="assets/images/landing/about/community-impact/logo/got-to-be-ng.png" alt="">
        </div>
      </div>
    </div>
  </section>

</div>
