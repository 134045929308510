@if (producerDetails()) {
<section class="detail container">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/']"><span class="material-symbols-outlined">home</span></a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <a [routerLink]="['/pages/producers']" class="fw-bold">Producers</a>
      </li>
    </ol>
  </nav>

  @defer (when !isLoading()) {
  <!-- Producer info -->
  <section class="mb-3">
    <!-- Main info -->
    <div class="d-none d-md-flex flex-row gap-5">

      <!-- Image -->
      <div class="detail__img animated fadeInLeft">
        <img [src]="producerDetails()?.img" alt="{{ producerDetails()?.name }}" (error)="handleImageError($event)"
          loading="eager" />
      </div>

      <!-- Description -->
      <div class="detail__main animated fadeInRight">

        <div class="mb-3">
          <h1 class="h2-bold text-secondary mb-0 head-font">
            {{producerDetails()?.name}}
          </h1>

          <h4 class="h6-semibold align-items-center d-flex gap-2 detail__location">
            <span class="material-symbols-outlined">
              location_on
            </span>
            {{producerDetails()?.location}}
          </h4>
        </div>

        <hr class="m-0">

        @defer(on viewport) {
        <div class="mt-3">
          <p [innerHTML]="sanitizeHtml(producerDetails().description ?? '')"></p>
        </div>
        } @placeholder{<div></div>}

      </div>

    </div>

    <!-- Main info mobile -->
    <div class="d-md-none">

      <img [src]="producerDetails()?.img" class="img-fluid animated fadeInDown" alt="{{ producerDetails()?.name }}"
        (error)="handleImageError($event)" />

      <h1 class="h3-bold text-secondary mt-3 head-font animated fadeInLeft">
        {{producerDetails()?.name}}
      </h1>

      <h4 class="h7-semibold align-items-center d-flex gap-2 detail__location mb-3 animated fadeInLeft">
        <span class="material-symbols-outlined">
          location_on
        </span>
        {{producerDetails()?.location}}
      </h4>

      <hr class="m-0">

      @defer(on viewport) {
      <div class="mt-3 animated fadeInLeft">
        <p [innerHTML]="sanitizeHtml(producerDetails().description ?? '')"></p>
      </div>
      } @placeholder{<div></div>}

    </div>

  </section>

  <div class="spacer-4"></div>

  @defer(on viewport) {
  @if(producerDetails().products.length) {
  <h2 class="section-title">Their products</h2>
  <div class="row">
    <app-carousel [items]="carouselProducerProducts()" [type]="'producerProduct'"
      [hasMoreItems]="producerDetails()?.hasMoreProducts"></app-carousel>
  </div>
  }
  } @placeholder{<div></div>}
  } @placeholder (minimum 300ms) {
  <h1 class="h2-semibold text-secondary head-font">
    Loading...
  </h1>
  }

</section>
}