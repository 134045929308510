<section class="position-relative">
  <div class="keen-slider shop-banner" #shopSlider [class.fadeInDown_ShopBanner]="!isShopBannerHidden()"
    [class.fadeOutUp_ShopBanner]="isShopBannerHidden()">
    @for (item of slides(); track item) {
    <div class="keen-slider__slide d-flex align-items-center shop-banner__slide">

      <!-- <div class="shop-banner__item">
        <h1 class="title" [innerHTML]="item.title">
        </h1>
        <h4 class="subtitle" [innerHTML]="item.text">
        </h4>
      </div> -->

      <img [src]="item.img()" class="shop-banner__img" alt="">

    </div>
    }
  </div>

  <div class="dots" [class.hide-dots]="isShopBannerHidden()">
    @for (dot of dotHelper(); track $index) {
    <button type="button" title="Slide {{$index + 1}}" (click)="slider.moveToIdx($index)"
      [class]="'dot ' + ($index === currentSlide ? 'active' : '')"></button>
    }
  </div>

</section>