import { Component, inject, input, InputSignal } from '@angular/core';
import { SidebarService } from '../../sidebar/sidebar.service';
import { SidebarCheckList } from '../../sidebar/sidebar.types';

@Component({
    selector: 'app-special-category-pill',
    imports: [],
    templateUrl: './special-category-pill.component.html',
    styleUrl: './special-category-pill.component.scss'
})
export class SpecialCategoryPillComponent {

  #sidebarService = inject(SidebarService);

  item: InputSignal<any> = input(null);

  onCategoryClicked() {
    const category = this.#setUpCategoryToNavigate();
    this.#sidebarService.goToCategory(category);
  }

  #setUpCategoryToNavigate() {
    const c = this.item();
    return {
      id: c.id,
      name: c.name.trim(),
      checked: true,
      key: c.id,
      sublist: c.subCategories?.map((e: any) => {
        e.name = e.name.trim();
        e.checked = false;
        e.key = `${c.id}-${e.id}`;
        return e;
      }) || []
    } as SidebarCheckList;
  }


}
