import { Component, computed, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AccountRecoveryService } from './account-recovery.service';
import { SignalsStoreService } from '../../shared/signals-store.service';

@Component({
  selector: 'app-account-recovery',
  imports: [RouterOutlet],
  providers: [AccountRecoveryService],
  template: `
    <div class="login vh-100 d-flex align-items-center">
        <div class="container">
            <div class="row d-flex align-items-center">
                @if(showSideLogo()) {
                    <section class="col-lg-4 h-100">
                        <img src="/assets/images/logo.webp?v={{logoVersionNumber()}}" alt="The Produce Box - Logo" class="login__logo mt-0">
                    </section>
                }
                <section [class]="containerClassList()">
                    <router-outlet></router-outlet>
                </section>
            </div>
        </div>
    </div>
  `,
  styleUrl: './account-recovery.component.scss'
})
export class AccountRecoveryComponent {
  private signalStoreService = inject(SignalsStoreService);
  private accountRecoveryService = inject(AccountRecoveryService);

  showSideLogo = computed(() => this.accountRecoveryService.showSideLogo());
  containerClassList = computed(() => this.showSideLogo() ? 'col-lg-6 offset-lg-2 h-100 d-flex align-items-center justify-content-center' : 'col-lg-6 d-grid mx-auto h-100 align-items-center justify-content-center');

  logoVersionNumber = computed(() => this.signalStoreService.logoVersionNumber());
}
