import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "./api.service";
import { environment } from "../../environments/environment";
import { SignalsStoreService } from "./signals-store.service";
import { RequestHandlerParams } from "./types/api-service.types";
import { filter, tap } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { ApiResponse } from "./common/types";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private HAS_TERRITORIES_FLOW: boolean = !!environment.config.flows.territories;

  private apiService = inject(ApiService);
  private signalStoreService = inject(SignalsStoreService);

  menu: WritableSignal<any[]> = signal([]);

  private selectedTerritory = toObservable(this.signalStoreService.selectedTerritory);

  private endpoints = {
    base: '/menu',
    categories: '/categories',
    territories: '/territories'
  };

  fetchMenuCategories(territory?: number) {
    this.menu.set([]);
    const endpoint = this.getMenuEndpoint(territory);

    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<{ categories: any[] }>>(params).pipe(
      filter((response: ApiResponse<{ categories: any[] }>) => !!response.data?.categories?.length),
      tap((response: ApiResponse<{ categories: any[] }>) => {
        this.signalStoreService.menuSignal.set(response.data.categories);
        this.signalStoreService.menu = [...response.data.categories];
      }),
      tap((response) => {
        if (response.data && response.data.categories.length) {
          this.signalStoreService
            .categoryInViewPort
            .set({
              categoryId: response.data.categories[0].id,
              subCategoryId: response.data.categories[0]?.subCategories[0]?.id,
            });
        }
      })
    );
  }

  private getMenuEndpoint(territory?: number) {
    const { base, territories, categories } = this.endpoints;

    let endpoint = `${base}`;
    if (territory)
      endpoint += `${territories}/${territory}`;
    endpoint += categories;

    return endpoint;
  }
}
