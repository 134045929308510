import { Routes } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { ShopComponent } from './shop/shop.component';
import { pagesRoutes } from './pages/pages.routes';
import { ProductComponent } from './product/product.component';
import { settingsRoutes } from './settings/settings.routes';
import { authGuard, checkAbandoneOrNoAddressdUser, maintenanceGuard, checkMembershipPermissions, checkNoPaymentMethodUser, maintenanceOffGuard, preventForAbandoneOrNoAddressdUser, hasSession } from './shared/guards/auth-guard.guard';
import { HomeComponent } from './home/home.component';
import { OrderComponent } from './order/order.component';
import { TermsAndConditionsComponent } from './authentication/signup/terms-and-conditions/terms-and-conditions.component';
import { accountRecoveryRoutes } from './authentication/account-recovery/account-recovery.routes';
import { FavProductsComponent } from './fav-products/fav-products.component';
import { BuyAgainProductsComponent } from './buy-again-products/buy-again-products.component';
import { CustomBoxComponent } from './shared/custom-box/custom-box.component';
import { CartPreviewComponent } from './cart-preview/cart-preview.component';
import { canDeactivateGuard } from './shared/guards/deactivate/can-deactivate.guard';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { WelcomeComponent } from './authentication/signup/welcome/welcome.component';
import { EcommerceAdminComponent } from './authentication/ecommerce-admin/ecommerce-admin.component';
import { productsResolver } from './resolvers/shop.resolver';

export const routes: Routes = [
  {
    path: 'maintenance',
    title: 'Maintenance',
    component: MaintenanceComponent,
    canActivate: [maintenanceOffGuard]
  },
  {
    path: 'ecommerce-admin/:token',
    title: 'Ecommerce Admin',
    component: EcommerceAdminComponent,
    canActivate: [maintenanceGuard]
  },
  {
    path: '',
    title: 'Home',
    component: HomeComponent,
    canActivate: [maintenanceGuard]
  },
  {
    path: 'login',
    title: 'Log In',
    component: LoginComponent,
    canActivate: [maintenanceGuard, authGuard]
  },
  {
    path: 'signup',
    title: 'Sign Up',
    component: SignupComponent,
    canActivate: [maintenanceGuard]
  },
  ...pagesRoutes,
  ...settingsRoutes,
  ...accountRecoveryRoutes,
{
    path: 'shop',
    component: ShopComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions, checkAbandoneOrNoAddressdUser],
    canActivateChild: [maintenanceGuard],
    resolve: {
      data: productsResolver
    }
  },
  {
    path: 'shop/:category',
    redirectTo: 'shop'
  },
  {
    path: 'shop/custom-box/:bundleEditionType/:productId',
    title: 'Shop Custom Box',
    component: CustomBoxComponent,
    pathMatch: 'full',
    canActivate: [maintenanceGuard, checkNoPaymentMethodUser]
  },
  {
    path: 'shop/:category/:subcategory/:product',
    title: 'Product',
    component: ProductComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions]

  },
  {
    path: 'favorites',
    title: 'Favorites',
    component: FavProductsComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions]

  },
  {
    path: 'buy-again',
    title: 'Buy again',
    component: BuyAgainProductsComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions]

  },
  {
    path: 'order',
    title: 'Order',
    component: OrderComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions, preventForAbandoneOrNoAddressdUser],
    canDeactivate: [canDeactivateGuard]
  },
  {
    path: 'cart',
    title: 'Cart',
    component: CartPreviewComponent,
    canActivate: [maintenanceGuard, checkMembershipPermissions]
  },
  {
    path: 'terms-and-conditions',
    title: 'Terms & Conditions',
    component: TermsAndConditionsComponent,
    canActivate: [maintenanceGuard]
  },
  {
    path: 'privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyPolicyComponent,
    canActivate: [maintenanceGuard]
  },
  {
    path: 'order/custom-box/:bundleEditionType',
    title: 'Order Custom Box',
    component: CustomBoxComponent,
    canActivate: [maintenanceGuard, checkNoPaymentMethodUser]
  },
  {
    path: 'order/custom-box/:bundleEditionType/:productId',
    title: 'Order Custom Box',
    component: CustomBoxComponent,
    canActivate: [maintenanceGuard, checkNoPaymentMethodUser]
  },
  {
    path: 'product/custom-box/:bundleEditionType/:productId',
    title: 'Product Custom Box',
    component: CustomBoxComponent,
    canActivate: [maintenanceGuard, checkNoPaymentMethodUser]
  },
  {
    path: 'signup/custom-box/:bundleEditionType/:productId',
    title: 'Sign Up Custom Box',
    component: CustomBoxComponent,
    canActivate: [maintenanceGuard, checkNoPaymentMethodUser]
  },
  {
    path: 'welcome',
    title: 'Welcome',
    component: WelcomeComponent,
    canActivate: [maintenanceGuard, hasSession]
  },
  {
    path: '**',
    redirectTo: ''
  }
];
