import { Component, computed, inject, Signal, WritableSignal } from '@angular/core';
import { Tag } from '../../../product/product.types';
import { ProductsService } from '../../../product/products.service';
import { handleImageError } from '../../common/utils';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-filtering-tags',
    imports: [CommonModule],
    templateUrl: './filtering-tags.component.html',
    styleUrl: './filtering-tags.component.scss'
})
export class FilteringTagsComponent {

  #productsService = inject(ProductsService);

  filteringTags: Signal<Tag[]> = computed(() => this.#setUpFilteringTags());

  handleImageError = handleImageError;

  #setUpFilteringTags() {
    const tags = this.#productsService.tagsSignal();

    return tags.filter(tag => tag.isChecked());
  }

  removeFromFilter(tag: Tag) {
    tag.isChecked.set(false);
    this.#productsService.filterBySelectedTags();
  }
}
