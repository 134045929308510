@if (deliveryZoneInfo()) {
<div class="animated fadeIn">
  <h1 class="d-none d-sm-block">{{session()?.settings?.requireAddress ? 'Deliveries' : 'Pick-Ups'}}</h1>

  @if (isTemporaryRouteChange()) {
  <app-empty-message [message]="temporaryRouteChangeMessage()"></app-empty-message>
  }

  @if (deliveryZoneInfo()?.order && !isTemporaryRouteChange()) {
  <h4 class="animated fadeInLeft">Change my {{isRequiredAddress() ? 'delivery' : 'pick up'}} this week</h4>
  <p class="animated fadeInLeft">This adjustment is temporary and your regular {{isRequiredAddress() ? 'delivery' :
    'pick up'}} will resume thereafter</p>

  <div class="mt-4 row row-gap-4">
    @for (item of deliveryOptions; track $index) {
    @if (item.isThisWeek) {
    <section class="col-sm-6 col-lg-4 animated fadeInDown">
      <div class="card" (click)="selectFromParent(item)">
        <div class="card-body">
          <div class="form-check d-flex flex-row align-items-center gap-3">
            <input class="form-check-input" type="radio" name="radioNextDeliveryDate" id="firstDeliveryDate{{item.id}}"
              (change)="setSelectedDeliveryDay($event, item)" [disabled]="isTemporaryRouteChange()"
              [checked]="deliveryZoneInfo()?.order?.deliveryDayId === item.id">
            <label class="form-check-label d-flex flex-column" for="firstDeliveryDate{{item.id}}">
              <small>Next {{isRequiredAddress() ? 'delivery' : 'pick up'}} date:</small>
              <b>{{item.formattedName}}</b>
            </label>
          </div>
        </div>
      </div>
    </section>
    }
    }
  </div>
  @if (selectedDeliveryDay()?.isThisWeek) {
  <div class="mt-4 row row-gap-4">
    @for (item of selectedDeliveryDay()?.timeSet; track $index) {
    <section class="col-sm-6 col-lg-4 animated fadeIn">
      <div class="card h-100 d-flex justify-content-center">
        <div class="card-body">
          <div class="form-check d-flex flex-row align-items-center gap-3">
            @if (selectedDeliveryDay()?.allowTimeWindow) {
            <input class="form-check-input" (change)="setSelectedDeliveryTimeWindow($event, item.id)"
              [disabled]="isTemporaryRouteChange()"
              [checked]="deliveryZoneInfo()?.order?.deliveryTimeWindowId ? item.id === deliveryZoneInfo()?.order?.deliveryTimeWindowId : item.id === -1"
              type="radio" name="radioTimeWindow">
            <label class="form-check-label d-flex flex-column pt-1">
              <b>{{item.name}}</b>
            </label>
            } @else {
            <label class="form-check-label d-flex flex-column pt-1">
              <b>{{item.name}}</b>
            </label>
            }
          </div>
        </div>
      </div>
    </section>
    }
  </div>
  }
  }


  <h4 class="mt-5 animated fadeInLeft">Change my {{isRequiredAddress() ? 'delivery' : 'pick up'}} every week</h4>
  <p class="animated fadeInLeft">
    This adjustment will affect your regular {{isRequiredAddress() ? 'delivery' : 'pick up'}}
  </p>

  <div class="row row-gap-4 mt-4 animated fadeInDown">
    <div class="col-12">
      <h4>{{isRequiredAddress() ? 'When should we deliver your order?' : 'When will you be picking up your order'}}</h4>
    </div>
    <div class="col-sm-6 col-lg-4">
      <mat-form-field class="w-100" [class.option-selected]="!!selectedDeliveryDay()">
        <mat-label>Choose a day</mat-label>
        <mat-select name="selectedDeliveryDay" [disabled]="isTemporaryRouteChange()"
          [value]="selectedDeliveryDayAll()?.id" (selectionChange)="setSelectedDeliveryDayAll($event)">
          @for (item of deliveryOptions; track $index) {
          <mat-option [value]="item.id">{{item.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-lg-4 d-flex align-items-end">
      @if (selectedDeliveryDayAll()?.allowTimeWindow) {
      <mat-form-field class="w-100 animated fadeInLeft" [class.option-selected]="!!selectedDeliveryTimeWindowAll()">
        <mat-label>Choose a time window</mat-label>
        <mat-select name="selectedDeliveryTimeWindow" [disabled]="isTemporaryRouteChange()"
          [value]="selectedDeliveryTimeWindowAll()" (selectionChange)="setSelectedDeliveryTimeWindowAll($event.value)">
          @for (item of selectedDeliveryDayAll()?.timeSet; track $index) {
          <mat-option [value]="item.id">{{item.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      } @else {
      <mat-form-field class="w-100 animated fadeInLeft">
        <mat-label>Time window</mat-label>
        <input type="text" matInput readonly="true" name="selectedDeliveryTimeWindow"
          [value]="selectedDeliveryDayAll()?.timeSet?.[0]?.name" [disabled]="isTemporaryRouteChange()">
      </mat-form-field>
      }
    </div>
  </div>

  @if(deliveryOptions && deliveryOptions.length) {
  <div class="d-grid col-lg-3 col-md-12 mx-auto mt-5">
    <button type="button" class="btn btn-primary"
      [disabled]="isSaveChangesDisabled() || isTemporaryRouteChange() || isWaitingForAPIResponse()"
      (click)="updateDeliveryInfo()">
      {{submitButtonText()}}
    </button>
  </div>
  }
</div>
}