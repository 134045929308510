<section class="section-hero has-animation fadeInUp" [class.height-section-hero-global-message]="hasGlobalMessages()">
  <div class="container z-1 section-padding-lg px-3">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="title is-left">
          <h1 class="title__main">Fresh. Local. Delivered.</h1>
          <p class="title__sub">The Produce Box<sup>®</sup> brings the farmers market to you with farm-to-door local
            food delivery
            in the Triangle.</p>
        </div>
        <div class="spacer"></div>
        <a class="btn btn-static btn-lg btn-secondary" routerLink='/signup'>Get Started</a>
      </div>
    </div>
  </div>
  <div class="hero-actions">
    <div class="container">
      <div class="action-btn-wrap">
        <a class="btn btn-static btn-lg btn-special" routerLink='/shop'>Visit Our Store</a>
        <a class="btn btn-static btn-lg btn-secondary-300" routerLink='/pages/producers'>Meet our Producers</a>
      </div>
    </div>
  </div>
</section>

<section class="py-5 has-curve-bottom has-animation fadeInLeft">
  <div class="container">
    <div class="text-banner">WE DELIVER TO
      <span #textAnime>Raleigh</span>
    </div>
  </div>
</section>

<section class="section-live-local has-padding-block-5 px-3 ">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 has-animation fadeInLeft">
        <div class="img-grid">
          <img src="assets/images/landing/home/live-local/produceBox.webp" class="img-main" alt="An produce box" />
          <img src="assets/images/landing/home/live-local/olderLady.webp" class="img-sub" alt="Older Lady" />
          <div class="img-grid-badge">
            <span class="icon material-symbols-outlined">grocery</span>
          </div>
        </div>
      </div>
      <div class="col-md-5 offset-md-1 has-animation fadeInRight">
        <h5 class="pre-header">LIVE LOCAL</h5>
        <h2 class="text-center text-md-start is-tertiary-font">Our online Farmers Market supports over 100 North
          Carolina Farmers and Artisan foodmakers</h2>
        <p class="text-justify h4-regular">Every time you place an order with The Produce Box, you shorten the supply
          chain and get fresh, locally-sourced food delivered right to your doorstep, all while reducing your carbon
          footprint. Together, we can build a better food system.</p>
        <a class="btn btn-static btn-lg btn-primary" routerLink='/signup'>Create an Account</a>
      </div>
    </div>
  </div>
</section>

<section class="section-why">
  <div class="bg-pattern bg-pattern-1"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 has-animation fadeInLeft">
        <h2 class="text-center text-md-start is-tertiary-font mb-4">Why shop with The Produce Box<sup>®</sup>?</h2>
        <ul class="ul-icon-list col-2">
          <li><span class="material-symbols-outlined list-icon">account_circle</span>Woman-owned and operated</li>
          <li><span class="material-symbols-outlined list-icon">psychiatry</span>High quality local products from
            producers that you can trust</li>
          <li><span class="material-symbols-outlined list-icon">sync</span>Returnable and low-waste packaging materials
          </li>
          <li><span class="material-symbols-outlined list-icon">where_to_vote</span>We support small businesses and
            farmers in YOUR community!</li>
          <li><span class="material-symbols-outlined list-icon">orders</span>We deliver low-cost fresh produce boxes to
            dozens of non-profits in the Triangle to serve our food-insecure neighbors</li>
          <li><span class="material-symbols-outlined list-icon">verified</span>15+ years in business as North Carolina's
            original farm-to-table grocery provider</li>
        </ul>
        <div class="d-flex justify-content-sm-start justify-content-center my-4">
          <a routerLink='/pages/about-us' class="btn btn-static btn-padding-lg btn-secondary mb-3">About us</a>
        </div>
      </div>
      <div class="col-md-5 offset-md-1 has-animation fadeInRight">
        <div class="img-grid">
          <img src="assets/images/landing/home/why/main.webp" class="img-main" alt="" />
          <img src="assets/images/landing/home/why/sub.webp" class="img-sub" alt="" />
          <div class="img-grid-badge">
            <span class="icon material-symbols-outlined">eco</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="py-5 section-img-grid px-3">
  <div class="bg-pattern bg-pattern-2"></div>
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main mb-5">Your One Box Shop</h2>
    </div>
    <div class="grid-info-list">
      <div class="grid-info">
        <div class="info">
          <h3 class="info__title text-secondary-light">Local Produce</h3>
          <p class="info__desc">Over 70% of our produce is sourced within 150 miles of our warehouse year-round. On
            average, our farmers earn three times more per dollar than farmers supplying traditional grocery stores! For
            items that don't grow here, we work with local aggregators to ensure a fresh, diverse selection.</p>
        </div>
        <div class="img">
          <img src="assets/images/landing/home/grid-info/1.png" alt="" />
        </div>
      </div>
      <div class="grid-info has-img-left">
        <div class="info">
          <h3 class="info__title text-info-light">MEAT, DAIRY AND EGGS</h3>
          <p class="info__desc">We hand-pick small providers of meat, dairy, and eggs to deliver the best products from
            North Carolina family farms to you. Pasture-raised, grass-fed, antibiotic-free, and Animal Welfare Approved
            products make up the majority of our offerings.</p>
        </div>
        <div class="img">
          <img src="assets/images/landing/home/grid-info/2.png" alt="" />
        </div>
      </div>
      <div class="grid-info">
        <div class="info">
          <h3 class="info__title text-tertiary-light">ARTISAN PRODUCTS</h3>
          <p class="info__desc">Stock your pantry, fridge, and freezer with artisan foods from your favorite NC makers.
            Enjoy fresh-baked bread, ready-to-heat meals, and healthy snacks, bringing local bakers and chefs to your
            table with home delivery convenience</p>
        </div>
        <div class="img">
          <img src="assets/images/landing/home/grid-info/3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center my-5">
    <a routerLink='/shop' class="btn btn-static btn-padding-lg btn-primary">Shop Now!</a>
  </div>
</section>

<section class="section-how has-padding-block-5 position-relative">
  <div class="bg-pattern bg-pattern-3"></div>
  <div class="container">
    <div class="how-banner">
      <div class="row">
        <div class="col-md-4 text-center text-md-start">
          <h2 class="how-title">How it Works</h2>
          <p class="how-desc">
            Create an account today to start shopping North Carolina’s freshest selection. Learn more about
            our $25 annual membership here
          </p>
          <a class="btn btn-static w-75 btn-secondary d-md-none mb-3" routerLink='/pages/our-membership'>
            Our Membership
          </a>
        </div>
        <div class="col-md-8 text-center">
          <div class="row has-animation fadeInUp">
            <div class="col-md-4">
              <div class="how-step">
                <span class="icon"><span class="material-symbols-outlined">person_check</span></span>
                <div class="detail">
                  <div class="num">1</div>
                  <div class="text">Sign up to become a member and receive your delivery day</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="how-step">
                <span class="icon"><span class="material-symbols-outlined">add_shopping_cart</span></span>
                <div class="detail">
                  <div class="num">2</div>
                  <div class="text">Shop from 400+ products every weekend and edit subscriptions as needed</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="how-step has-no-dash">
                <span class="icon"><span class="material-symbols-outlined">local_shipping</span></span>
                <div class="detail">
                  <div class="num">3</div>
                  <div class="text">We deliver straight to you!</div>
                </div>
              </div>
            </div>
          </div>
          <a class="btn btn-static w-75 btn-secondary d-md-none mt-3" routerLink='/signup'>
            Sign Up Today!
          </a>
        </div>
      </div>
      <div class="row mt-3 d-none d-md-flex">
        <div class="col-md-4">
          <a class="btn btn-static w-100 btn-secondary" routerLink='/pages/our-membership'>Our Membership</a>
        </div>
        <div class="col-md-8 d-flex justify-content-center">
          <a class="btn btn-static btn-padding-lg btn-secondary" routerLink='/signup'>Sign Up Today!</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-categories mb-5">
  <div class="container">
    <div class="title is-center">
      <h3 class="title__main">Popular Categories</h3>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-4" *ngFor="let category of popularCategories">
        <div class="card-category">
          <img src="{{category.img}}" class="card-category__img" alt="{{category.text}}">
          <h3 class="card-category__title">{{category.text}}</h3>
          <button type="button" class="btn" [routerLink]="[category.link]">Shop Now</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-delivery px-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5 offset-md-1 order-md-2 has-animation fadeInLeft mb-5">
        <h2 class="text-center text-md-start is-tertiary-font">Neighbors delivering local food to neighbors.</h2>
        <p class="h4-regular">Our delivery model relies on Neighborhood Ambassadors, people just like you who deliver
          fresh, local food to the doorsteps of their community. By choosing The Produce Box, you're not just shopping
          online for your groceries. You’re supporting local farmers, strengthening your neighborhood, and fueling our
          local economy.</p>
      </div>
      <div class="col-md-6 order-md-1 has-animation fadeInRight">
        <div class="img-grid">
          <img src="assets/images/landing/home/live-local/main.webp" class="img-main-alone" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-testimonials has-padding-block-5 position-relative">
  <div class="bg-pattern bg-pattern-2"></div>
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main">What our customers are saying...</h2>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="testimonial is-info">
          <blockquote>I love the convenience of getting local goods delivered to my home. I always want to support
            local, but sometimes making it out to the farmer's market is overwhelming. The bundles are especially handy.
            The food is always fresh and delicious, and the subscriptions are flexible.</blockquote>
          <cite><span>Martha S</span></cite>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="testimonial is-danger">
          <blockquote>We love using the Produce Box at our company. We receive a box of fresh fruit for our employees
            to have healthier alternatives to snack on. I love how you can add items to your orders or skip a week if
            needed. Also very convenient that they send you a text when on the way so you know when to expect the
            delivery. Highly recommend for fresh fruit, produce, and many others!</blockquote>
          <cite><span>Chelsea W</span></cite>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="testimonial is-success">
          <blockquote>We love the Produce Box and supporting our local farmers and vendors. We have 3 out of 4 family
            members with celiac disease and can be a challenge and expensive to shop for. There are lots of gluten free
            options including a local bakery. Highly recommend.</blockquote>
          <cite><span>Kristen H</span></cite>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <a routerLink='/signup' class="btn btn-static btn-padding-lg btn-secondary">Sign Up Now</a>
    </div>
  </div>
</section>

@if (producersCards().length) {
<section class="section-producers mb-5 position-relative">
  <div class="bg-pattern bg-pattern-5"></div>
  <div class="container">
    <div class="title is-center">
      <h3 class="title__main">Meet Your Producers</h3>
    </div>
    <div class="row">
      @for (producer of producersCards(); track $index) {
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div class="card-producer">
          <img [src]="producer.img" class="card-producer__img" alt="Image of the producer {{producer.name}}"
            (error)="handleImageError($event)" />
          <a [routerLink]="['/pages/producers/' + producer.detailUrl]">
            <h3 class="card-producer__title">
              {{producer.name}}
            </h3>
          </a>
          <hr>
          <div class="card-producer__location">
            <span class="material-symbols-outlined">location_on</span>
            <span>{{producer.location}}</span>
          </div>
          <div class="card-producer__detail" [innerHTML]="producer.description"></div>
          <button type="button" class="btn btn-static btn-outline-primary" [routerLink]="['/shop']">
            Shop Now
          </button>
        </div>
      </div>
      }
    </div>
    <div class="d-flex justify-content-center mt-4">
      <a routerLink='/pages/producers' class="btn btn-static btn-secondary">Our Partners</a>
    </div>
  </div>
</section>
}

<section class="has-padding-block-5 px-3">
  <div class="container">
    <div class="row mx-0 d-flex row-gap-3 column-gap-0">
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/1.png" class="grid-card__img">
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/2.png" class="grid-card__img">
          <div class="grid-card__detail">
            <div>
              <p class="text">Still have questions? See if we've answered them here:</p>
              <a routerLink='/pages/faq' class="btn btn-static btn-sm btn-secondary">FAQs</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/3.png" class="grid-card__img">
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <h2 class="only-text">Learn More About our Impact</h2>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/4.png" class="grid-card__img">
          <div class="grid-card__detail">
            <div>
              <p class="text">Here’s how we're increasing food access in our area</p>
              <a routerLink='/pages/community-impact' class="btn btn-static btn-sm btn-secondary">Community Impact</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/5.png" class="grid-card__img">
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/6.png" class="grid-card__img">
          <div class="grid-card__detail">
            <div>
              <p class="text">Ready to bring healthy food to your workplace? </p>
              <a routerLink='/pages/workplace-wellness' class="btn btn-static btn-sm btn-secondary">Workplace
                Wellness</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 px-2">
        <div class="grid-card">
          <img src="assets/images/landing/home/grid-card/7.png" class="grid-card__img">
        </div>
      </div>
    </div>
  </div>
</section>



<div class="pre-footer">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 text-sm-start text-center">
        <h1 class="title"><span>Stay in touch</span> and earn $10 off your first order! </h1>
      </div>
      <div
        class="col-md-6 d-flex align-items-center justify-content-center  justify-content-md-end  gap-3  text-sm-start text-center mt-5 mt-sm-0">
        <div class="klaviyo-form-XYTrAM"></div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>