import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgForOf } from "@angular/common";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-about-us',
    imports: [RouterOutlet, NgForOf],
    templateUrl: './about-us.component.html',
    styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements OnInit {

  private meta = inject(Meta);
  private title = inject(Title);

  flashCard = [
    {
      img: 'about/flash-card/AboutUs_LocalSourcing-min.webp',
      title: 'Local Sourcing',
      desc: 'We source from <strong>Western North Carolina, the Southern Appalachians and the South East region</strong>. With our network of farms spanning lush hillsides, fertile bottom lands, rolling foothills, and gentle piedmont, our approach ensures our produce is fresh and deeply rooted in the rich agricultural traditions of our community.'
    },
    {
      img: 'about/flash-card/AboutUs_Variety-min.webp',
      title: 'Variety',
      desc: '<strong>You\'ll find an array of fresh, local and regionally sourced delights</strong>. Our selection includes seasonal produce, artisanal cheeses, proteins, baked goods, prepared meals, pantry items, beverages, gifts, and more. We celebrate  community and craft, and every item we source reflects the rich flavors of our region.'
    },
    {
      img: 'about/flash-card/AboutUs_Freshness-min.webp',
      title: 'Unparalleled Freshness',
      desc: 'Our commitment to sourcing locally means <strong>your fresh produce goes straight from dirt to doorstep in two days time</strong>. Enjoy the convenience of high quality groceries delivered right to your doorstep, harvested and delivered with care from our local farmers to you.'
    }, {
      img: 'about/flash-card/AboutUs_Sustainability-min.webp',
      title: 'Sustainability',
      desc: '<strong>At The Produce Box Food, sustainability is at our core. Through eco-friendly packaging, recycling, and composting programs, we\'ve reduced food waste.</strong> Our proactive ordering system keeps food waste under 1%, with surplus perishables donated to those in need through our community partners. We manage non-perishables carefully to minimize waste.'
    }
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.setUpSEOTags();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/about-us'
    const component = 'About Us'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

}
