import { Component, Input, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductsService } from '../../product/products.service';
import { CommonModule } from '@angular/common';
import { ProductCardV2Component } from '../product-card-v2/product-card-v2.component';

@Component({
  selector: 'app-product-selection-list',
  imports: [RouterLink, ProductCardV2Component, CommonModule],
  templateUrl: './product-selection-list.component.html',
  styleUrl: './product-selection-list.component.scss'
})
export class ProductSelectionListComponent {

  @Input() productListType = '';

  productsService = inject(ProductsService);

}
