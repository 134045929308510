import { inject, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';

import { ResolveFn } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { isPlatformServer } from '@angular/common';

const SHOP_DATA_KEY = makeStateKey<any>('shopData');

export const productsResolver: ResolveFn<any> = async (route, state) => {
    const http = inject(HttpClient);
    const transferState = inject(TransferState);
    const platformId = inject(PLATFORM_ID);


    const api = environment.apis.v3;

    const endpoints = {
        products: `${api}/categories-resume`
    };

    const existingData = transferState.get(SHOP_DATA_KEY, null);
    if (existingData) {
        return new Observable((observer) => {
            observer.next(existingData);
            observer.complete();
        });
    }

    const requests = http.get(endpoints.products);

    if (isPlatformServer(platformId)) {
        requests.subscribe(data => transferState.set(SHOP_DATA_KEY, data));
    }

    return requests;
};
