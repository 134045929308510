<div class="login vh-100 d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12 col">
                <img src="/assets/images/logo.webp?v={{logoVersionNumber()}}" alt="The Produce Box - Logo"
                    class="login__logo">

                <h5 class="text-lg-start text-md-start text-center mb-4 mt-5 mt-lg-0">
                    IMPORTANT message for returning The Produce Box customers
                </h5>

                <p class="text-lg-start text-md-start text-center">
                    Welcome to our <b>NEW</b> e-Commerce experience! To gain access to your existing The Produce Box
                    account
                    click the button below to reset your password and log in to your The Produce Box account.
                </p>

                <p class="text-lg-start text-md-start text-center">
                    If you run into any issues, please email <a class="fw-bold"
                        [href]="'mailto:'+contactEmail()">{{contactEmail()}}</a> and we will help you log in. Thank you
                    for all your support!
                </p>

                <div class="d-grid col-12">
                    <button class="btn btn-secondary w-100" (click)="goTo('/account-recovery/check-email')">
                        Access my account now!
                    </button>
                </div>

                <div (click)="goTo('/login')" class="login__footer-action">
                    <span class="action-icon material-symbols-outlined">west</span>
                    <span class="action-label">Back to login</span>
                </div>

            </div>
        </div>
    </div>
</div>