<section class="page-wrap animated fadeIn">
  <div class="container">
    <div class="title is-center is-full-width ">
      <h2 class="title__main mb-3">More than a membership, it’s a movement.</h2>
      <p class="mb-5">When you sign up for The Produce Box and pay your $25 annual fee, you’re casting a powerful vote
        for the world you want to live in - for just over $2 a month. Here’s how your membership makes an extraordinary
        impact</p>
    </div>
    <div class="step-vertical-grid is-secondary">
      <div class="step-img"><img src="/assets/images/landing/about/our-membership/main-1.webp"
          alt="Our local and regional farm partners" /></div>
      <div class="step-number is-first">
        <div class="number">
          <img src="assets/images/landing/about/our-membership/icon-1.png" />
        </div>
      </div>
      <div class="step-info">
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Feeding Our Food-Insecure Neighbors with Fresh Produce</div>
            <div class="desc">Your membership fee is a catalyst for change, helping us pack an astounding
              <strong>25,000–50,000 pounds</strong> of fresh fruits and veggies every single month into low-cost Produce
              Boxes for nonprofits serving food-insecure families across North Carolina. That’s tens of thousands of
              pounds of nourishment going directly to those who need it most—month after month, box after box. When you
              join The Produce Box, your membership isn’t just feeding your family – it’s feeding hope, nourishment, and
              community for countless others.</div>
            <a href="/pages/community-impact" class="btn btn-static btn-secondary">Read more about our Community
              Impact</a>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid is-secondary change-order">
      <div class="step-img"><img src="/assets/images/landing/about/our-membership/main-2.webp"
          alt="Customize your box" /></div>
      <div class="step-number">
        <div class="number">
          <img src="assets/images/landing/about/our-membership/icon-2.png" />
        </div>
      </div>
      <div class="step-info">
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Sustainable Packaging, Designed for Reuse</div>
            <div class="desc">Unlike typical online grocers or meal kit services that generate mountains of single-use
              plastic waste, The Produce Box is leading the way with <strong>reusable, sustainable packaging</strong>
              designed to last. Each delivery arrives in a returnable Produce Box, and we’ve created a simple system to
              keep waste out of landfills: just leave out your box, quart containers, rubber bands, and ice packs from a
              previous order on delivery day, and we’ll collect and sanitize them for reuse.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid is-secondary">
      <div class="step-img"><img src="/assets/images/landing/about/our-membership/main-3.webp"
          alt="Continue Shopping" /></div>
      <div class="step-number">
        <div class="number">
          <img src="assets/images/landing/about/our-membership/icon-3.png" />
        </div>
      </div>
      <div class="step-info">
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Loyalty Rewards that Support Healthy Habits</div>
            <div class="desc">Building a consistent, healthy habit of eating local shouldn't be complicated. Every ten
              orders, you’ll receive a <strong>10% off coupon</strong> as a thank you for prioritizing your health and
              our shared food system. It’s more than a discount—it’s encouragement to stay on the path of nourishing
              your body, your family, and your community with every meal.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid is-secondary change-order">
      <div class="step-img"><img src="/assets/images/landing/about/our-membership/main-4.webp"
          alt="We deliver your order in recyclable packaging" /></div>
      <div class="step-number ">
        <div class="number">
          <img src="assets/images/landing/about/our-membership/icon-4.png" />
        </div>
      </div>
      <div class="step-info">
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Exclusive Access to Local Events & Behind-the-Scenes Stories:</div>
            <div class="desc">Your membership unlocks unique opportunities like <strong>farm tours, tastings with local
                producers, and behind-the-scenes stories</strong> that bring you closer to the people and places behind
              your food. Always optional, these events are invitations to connect deeply with the real farmers and
              artisans who stock your Produce Box.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-vertical-grid is-secondary">
      <div class="step-img"><img src="/assets/images/landing/about/our-membership/main-5.webp"
          alt="We deliver your order in recyclable packaging" /></div>
      <div class="step-number is-last">
        <div class="number">
          <img src="assets/images/landing/about/our-membership/icon-5.png" />
        </div>
      </div>
      <div class="step-info">
        <div class="step-content-flex">
          <div class="step-text">
            <div class="title">Referral Bonuses for Growing our Community</div>
            <div class="desc">Love The Produce Box? Share the local love! With our <strong>“Give $15, get $15!” referral
                program</strong>, you can invite friends and family to enjoy fresh, local food while earning credits for
              yourself. Share your unique referral link, and your friends will receive $15 off their first order. Once
              they place an order, you’ll get $15 in store credit. The more you share, the more you earn—there’s no
              limit. Together, we can grow a community dedicated to eating local, supporting farmers, and building a
              sustainable future, one box at a time.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-banner has-padding-block-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
        <p class="text-center">Of course, you can cancel your membership at any time by reaching out to our Member
          Solutions Team. But we hope you’ll stay, year after year, and support our mission of building a better food
          system and future!</p>
      </div>
      <div class="col-sm-2"></div>
    </div>
    <div>
      <hr>
      <div class="title is-center is-full-width mt-4">
        <h2 class="title__main mb-5">Sound like something you can get behind?</h2>
      </div>
      <div class="d-flex justify-content-center">
        <a class="btn btn-lg btn-primary" [routerLink]="['/shop']">Visit our Store</a>
      </div>
    </div>
  </div>
</section>
