<div class="h-100 animated fadeIn">
    <div class="p-0 d-flex justify-content-start animated fadeInDown">
        <small class="text-secondary fw-light pt-0 d-flex d-md-none align-items-center gap-1">
            <span
                class="material-symbols-outlined"
                style="font-variation-settings: 'FILL' 1, 'wght' 700;">
                    verified
            </span>
            Product item added
        </small>
    </div>
    <div class="close-btn" (click)="clearJustAddedProduct()">
        <span class="material-symbols-outlined">
            close
        </span>
    </div>


    <app-product-cart-preview
        [product]="justAddedProduct()"
        [isJustAddedProductNotification]="true">
    </app-product-cart-preview>

    <button
        class="btn btn-outline-secondary btn-sm d-block d-lg-none w-100"
        (click)="goToCart()">
        Go to cart
    </button>
</div>
