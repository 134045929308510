import { Component, inject, OnInit } from '@angular/core';
import { FADEIN_OUT_ANIMATION } from '../../../shared/constants/animations';
import { NgForOf } from "@angular/common";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { environment } from '../../../../environments/environment';

@Component({
    // animations: FADEIN_OUT_ANIMATION,
    selector: 'app-members',
    imports: [
        NgForOf,
        NgbAccordionModule
    ],
    templateUrl: './members.component.html',
    styleUrl: './members.component.scss'
})
export class MembersComponent implements OnInit {

  private meta = inject(Meta);
  private title = inject(Title);

  faq = [
    {
      count: 1,
      question: 'I was an existing Produce Box customer on the old website. How do I access the new website?',
      answer: 'In order to log into our new website, you’ll need to reset your password. When you go to log in, follow the forgot password prompts. Once you log in, you should find all of your subscription preferences, payment information, delivery notes, and any credit balances pre-loaded into your account and be able to shop with us as usual. If you have any questions about your account or the new website, please contact our Member Solutions Team at <a href=\'mailto:info@theproducebox.com\'>info@theproducebox.com</a> or 919-904-4722.'
    },
    {
      count: 2,
      question: 'How do I create an account to start shopping with The Produce Box?',
      answer: `To begin, click “<a href=\'/signup\'>Sign Up</a>” on our website. By entering your home delivery address, our system will immediately check to see if you are within our delivery area, and if so, what day we deliver to you. In a few simple steps, you’ll set up your basic account information, including your contact information, delivery address, helpful notes for our drivers, and payment method.<br><br>
      At this signup stage, you can also subscribe to and customize your first curated Produce Box full of fresh, local fruits and veggies!<br><br>
      By signing up and entering your payment information, an invoice will automatically be generated and your card will be charged our $25 annual membership fee within 1-2 business days.`
    },
    {
      count: 3,
      question: 'Why do you charge a $25 annual membership fee? When will I pay this charge?',
      answer: `Our annual membership fee allows us to invest in quality, sustainability, and community. <a href=\'/pages/our-membership\'>Read more on the home page here</a>. In short, your membership supports:<br><br>
      <ul>
        <li>
          <strong>Supporting food access programs:</strong> Each week, we pack tens of thousands of pounds of fresh fruits and veggies into Produce Boxes for distribution to our non-profit partners. Your membership fee supports the infrastructure that makes these <a href=\'/pages/community-impact\'>low-cost programs</a> possible.
        </li>
        <li>
          <strong>Returnable, low-waste, sustainable packaging:</strong> We invest in packaging materials that are cleaned and reused week to week.
        </li>
         <li>
          <strong>Built-in loyalty rewards:</strong> We honor and incentivize your commitment to eating local by offering a 20% off coupon every ten orders!
        </li>
         <li>
          <strong>Exclusive events and in-box prints:</strong> We periodically offer members-only farm tours, tastings, and other events.
        </li>
      </ul>
      When you sign up and enter your payment information, an invoice will automatically be generated and your card will be charged our $25 annual membership fee within 1-2 business days.
      `
    },
    {
      count: 4,
      question: 'When can I place my order?',
      answer: 'Our menu is open for shopping every Friday morning at 9am until Sunday night at 9pm. If you’re signed up for our email and SMS marketing, you’ll get a text and an email Friday morning when the menu is open.<br>If you subscribe to one of our curated Produce Boxes, you don’t even need to log in and check out to get peak-season produce delivered to your door that week. You’re always welcome to make any changes or customizations to your Box–just make sure you do it before 9pm on Sunday!'
    },
    {
      count: 5,
      question: 'Is there an order minimum? How do I get free delivery? ',
      answer: 'Orders must be at least $28 for us to deliver to you. Our delivery fee is $6.99 unless your subtotal is $50 or more-then you get free delivery!'
    },
    {
      count: 6,
      question: 'Where do you deliver?',
      answer: 'We are currently delivering all across the Triangle area, including Raleigh, Durham, Chapel HIll, Cary, Holly Springs, Apex, and additional neighborhoods, <a href=\'/pages/delivery-areas\'>visible on this map.</a> When you go to create an account with us, our website will immediately determine if you are eligible for delivery and, if so, what day we deliver to you.'
    },
    {
      count: 7,
      question: 'When do you deliver?',
      answer: 'Our team of Neighborhood Ambassadors - our dedicated delivery drivers who are community members just like you that live in YOUR neighborhood - will deliver your order on Tuesday or Wednesday between 6:30am-5pm. You will get a text when your order is on the way, and once it has been successfully delivered.'
    },
    {
      count: 8,
      question: 'Where will my box be left?',
      answer: `Upon signup and at any time in your profile, you can add or edit “delivery instructions” for your driver, telling them exactly where you’d like your box to be left. If possible, please designate a shady area to protect your order on hotter days. If you leave out a cooler with ice packs, your Neighborhood Ambassador will transfer sensitive items from your box into your cooler.<br><br>
      If the weather is questionable (during our colder months or the hurricane season), we will evaluate and contact you via email with updates. Every once in a while we do have to reschedule deliveries if it is too dangerous for our Neighborhood Ambassadors to be on the road.`
    },
    {
      count: 9,
      question: 'Do I have to subscribe to a Produce Box?',
      answer: 'No, you are not required to subscribe to one of our curated Produce Boxes in order to shop with us. Feel free to build your order completely from scratch with everything you want across varied product categories like local pasture-raised meat, farm-fresh dairy, frozen meals, fresh-baked bread, and so much more!'
    },
    {
      count: 10,
      question: 'What are the benefits of a recurring subscription?',
      answer: `Subscribing to a Produce Box or individual grocery items offers convenience and variety. <br><br>
      <ul>
        <li>
          <strong>Set it and forget it:</strong> Subscribing to a Produce Box ensures that you’ll always have a variety of fresh, local produce on hand when you need it. Each week, we’ll curate the best selection of peak-season produce and add these items to your subscription box, even if you don’t log in. Never have an empty fridge!
        </li>
        <li>
          <strong>Customizable for your needs and schedule:</strong> Enjoy fresh, local produce and other food items delivered on your schedule—weekly, biweekly, or monthly—and choose add-ons to suit your needs. Skip or pause deliveries anytime.
        </li>
        <li>
          <strong>Farmer-focused:</strong> Support local farmers by helping them plan their crops, and explore new seasonal flavors with curated Produce Boxes. Oftentimes, a single Farm Box will support 3-5 different NC farmers!
        </li>
        <li>
          <strong>Simplify with la carte subscriptions:</strong> You can also set up recurring subscriptions for favorite staples like eggs or chicken, saving time and ensuring you always have what you need. Just remember, your subscriptions must add up to at least $28 to auto-ship.
        </li>
      </ul>
      `
    },
    {
      count: 11,
      question: 'How do I customize my curated Produce Box?',
      answer: 'After adding a Produce Box to your cart or subscriptions, click “Customize your Box” to adjust quantities of included items or explore recommended add-ons. Want more variety? Browse the entire site to create an order that’s as unique as you!'
    },
    {
      count: 12,
      question: 'How do I cancel or update the frequency of my subscriptions, or skip a delivery date?',
      answer: `To view all upcoming deliveries, go to the “Subscriptions” page in your account. From this page, you can update the frequency of your deliveries for each item or Produce Box, or skip an upcoming delivery. To pause all your subscriptions for an extended period, use the green “Pause Subscriptions” button in the upper right corner. Currently, you can only pause deliveries for subscription items on consecutive delivery dates.`
    },
    {
      count: 13,
      question: 'Where and how do you source your products? Is all of your produce organic?',
      answer: `We develop close relationships with our farm and artisan partners across North Carolina, and we prioritize local producers within 150 miles of our warehouse in Raleigh. Every product will have information about the producer listed on the product card with relevant tags about the production practices such as “organic”, “local”, “gluten free”, etc.<br><br>
      Some of our producers are USDA certified organic, but others are not. You can filter our site by “organic” or other tags such as “naturally grown.” We recognize that the organic certification process for small farmers is often too costly and admin-intensive to pursue, so we strive to give you as much information as possible about how your food is grown. We cannot officially call all of our farmers certified organic, but we do trust that the farmers we work with raise crops that are healthy for them, their farms, and anyone who consumes the food they raise.<br><br>
      Some of the non-local or out of season produce we carry is grown using conventional growing methods. We have decided to focus our efforts on expanding the availability of our local producers rather than spend our efforts to source more non-local organic.`
    },
    {
      count: 14,
      question: 'What standards do you hold for your meat, dairy, and egg farmers?',
      answer: `All of our meat and dairy is raised with no hormones or antibiotics. We sell many pasture-raised/grass-fed and Animal Welfare Certified options as well, which you can find in the tags and descriptions of the relevant products. These animals may be given supplemental feed in the winter months when there is less to eat in the fields.<br><br>
      We provide options between cage-free, pasture-raised, and conventional meat, dairy, and egg products from small family farmers in North Carolina so that our customers can find a price point and a product that works best for them and their families!`
    },
    {
      count: 15,
      question: 'What packaging is associated with my order? How do you minimize packaging waste?',
      answer: `Returnable and low-waste packaging materials is a priority for us! On delivery day, please leave out your cardboard Produce Box, and inside, any rubber bands, blue quart containers, and ice packs from a previous order. That way, your Neighborhood Ambassador can pick up these materials to bring back to our warehouse for reuse week to week. Currently, the only packaging materials that we are not accepting the insulated thermal liners back for re-use. We’re working on finding a solution for easy recycling or reuse of these items!`
    },
    {
      count: 16,
      question: 'What is your policy on allergies?',
      answer: `Right now, we do not have a policy in place to use separate boxes for members with allergies. You can choose to order a box that does not contain an item that you are allergic to. However, we cannot guarantee that there is no residue in any box since they are recycled back to us each week from members.<br><br>
      Items that are tagged “Gluten Free” or “Vegan” may not be made in Certified Gluten Free or 100% Gluten Free kitchens. Please reach out to us if you have any questions about ingredients and allergens.`
    },
    {
      count: 17,
      question: 'Can I tip my driver?',
      answer: `Yes! 100% of your tip will go straight to your Neighborhood Ambassador. Simply add a tip in the tip field at checkout. You can also add a recurring tip, so that every order you place, including any subscription orders, will auto-apply a tip for your driver. We think they provide exceptional customer service, and they’re always grateful for some extra love!.`
    },
    {
      count: 18,
      question: 'What if I have a concern with the quality of an item in my box, or I appear to be missing something from my order?',
      answer: `Your satisfaction is important to us! We have several ways you can reach out to request a credit for an item in your box. Please reach out to our Member Solutions team at <a href=\'mailto:info@theproducebox.com\'>info@theproducebox.com</a>, our phone line at 919-904-4722, or by filling out <mark><a href=\'https://docs.google.com/forms/d/e/1FAIpQLSdwuVi2tVtBiB36YPCE0BLk8slF6nDQ-sD5GxPxGBpJuwMDEA/viewform?usp=sf_link\'>this form</a></mark>, which you can also find <mark>via the button in your profile titled “Need a Credit?” under “Order History”.</mark>`
    },
    {
      count: 19,
      question: 'What if I need to make a change to my order after I have checked out?',
      answer: `If you’ve already placed an order but want to make a change before the 9pm Sunday deadline, you can simply log into your account make changes to your cart. Please note that you will have to click “Update/Submit Order” again with your finalized order. You may receive two order confirmation emails, but know that when you make any changes to your order, you have not duplicated your order. Each order submission overwrites the previous one, so you can change your order as many times as you like throughout the ordering period.<br><br>
      All changes to your order must be made before the menu closes at 9pm on Sunday night. We are unable to accommodate changes to your order after the deadline. We would be happy to donate your box to one of our non-profit partners if you won’t be able to receive it, or, you can also gift your box to a friend or neighbor if you know of someone who might enjoy it! If you'd like to donate your order, please contact <a href=\'mailto:info@theproducebox.com\'>info@theproducebox.com</a>.`
    },
    {
      count: 20,
      question: 'How do I update my personal information, delivery address, or credit card information?',
      answer: `To update your information, log in and go to the “Profile” section of your account, where you can make necessary changes. When changing your delivery address, please note that your delivery day may change. If you have moved out of our delivery area, you will no longer be eligible for delivery. Our system will let you know the outcome of your delivery address change as soon as you plug in the new address.`
    },
    {
      count: 21,
      question: 'How do I view my account balance, past orders, payments due, etc?',
      answer: `To view your account balance, log in to your account, then navigate to the “Orders and Billing” page. Any credits on your account will appear here, as will past orders and invoices.<br><br>
      Click the “Order Details” hyperlink for a full list of your purchases, as well as to choose to add the same order back to your cart for repeat purchases of items you love!`
    },
    {
      count: 22,
      question: 'Why are there sometimes non-local items in my curated Farm Box?',
      answer: `During the fall and winter, the <strong>variety</strong> of local produce availability decreases. In our 15+ years in business, we’ve found that during the colder months, our customers have a hard time eating ONLY  local items all winter--lots of sweet potatoes, kale, cabbage, root veggies, and apples! By adding other items to the Boxes in the winter, like juicy citrus from Florida or fresh avocados, we're able to <strong>support</strong> our local NC farmers who are still harvesting, <strong>employ</strong> our staff, and <strong>deliver</strong> fresh fruits and veggies to our customers year-round, ensuring that YOU always have a nutritious supply of fruits and veggies on hand.<br><br>
      You can always find and shop exclusively LOCAL produce on our webstore by applying the “LOCAL” filter when browsing. That way, you’ll be able to shop produce that is exclusively grown within 150 miles of Raleigh!`
    },
    {
      count: 23,
      question: 'How do I cancel my membership?',
      answer: `To cancel your Produce Box membership, <a href=\'https://docs.google.com/forms/d/e/1FAIpQLSfOP1gTxGbEO5Fb74N7El6RLYl-cudzRJJEV_OHN-eyYnq8Qw/viewform?usp=sf_link\'>complete <mark>this form</mark></a> and a member from our team will reach out to confirm via email after your cancellation has been processed. If you have an upcoming order and you request to cancel your account after 9pm on Sunday, you will still be responsible for that order. Please also note that if you have an outstanding balance in your account, you will not be able to cancel your account until that balance is paid.`
    },
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.setUpSEOTags();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/faq'
    const component = 'FAQ'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}
