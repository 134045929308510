import { CommonModule } from '@angular/common';
import { Component, WritableSignal, inject, signal, computed, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Referral, ReferralProgram } from './friend-referrals.types';
import { environment } from '../../../../environments/environment';
import { ResolutionService } from '../../../shared/resolution.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '../../../shared/notification/notification.service';
import { FriendReferralsService } from './friend-referrals.service';
import { tap } from 'rxjs';
import { formatDateToReadableString } from '../../../shared/utils/formatting';

@Component({
    selector: 'app-friend-referrals',
    imports: [CommonModule, NgbModule],
    providers: [FriendReferralsService],
    templateUrl: './friend-referrals.component.html',
    styleUrl: './friend-referrals.component.scss'
})
export class FriendReferralsComponent implements OnInit {

  private friendReferralService = inject(FriendReferralsService);
  private resolutionService = inject(ResolutionService);
  private clipboard = inject(Clipboard);
  private notificationService = inject(NotificationService);

  referralProgram: WritableSignal<ReferralProgram | undefined> = signal(undefined);
  referralBaseUrl = signal(environment.config.friendReferral.baseUrl);
  isMobile = computed(() => this.resolutionService.isMobile());
  mobileReferralListCollapsed = signal(true);

  howItWorksData = [
    {
      step: 1,
      textContet: 'Share your <b>unique referral link</b> with friends and family'
    },
    {
      step: 2,
      textContet: 'Anyone who uses your referral link to sign up and become a member will <b>receive an exclusive discount</b> on their first order'
    },
    {
      step: 3,
      textContet: 'Continue sharing the local love with new friends and family to earn and gift credits while <b>supporting your local producers</b>'
    },
  ]

  constructor() {
  }

  ngOnInit(): void {
    this.getReferralData();
  }


  private getReferralData() {
    this.friendReferralService.get().pipe(
      tap((data: ReferralProgram | undefined) => {
        data?.referrals.forEach((referral: Referral) => {
          const formattedDate = formatDateToReadableString(referral.signupDate);
          referral.shownSignupDate = `${formattedDate.monthNumber}/${formattedDate.dayNumber}/${formattedDate.year}`;
        });
        this.referralProgram.set(data);
      })
    ).subscribe();
  }

  copyCodeToClipboard() {
    this.clipboard.copy(`${this.referralBaseUrl()}${this.referralProgram()?.referralCode}`);
    this.notificationService.show({ text: 'Link copied to clipboard!', type: 'success' });
  }
}
