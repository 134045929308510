<!-- Sidebar -->
<nav class="sidebar-content {{hasGlobalMessages() ? 'height-sidebar-global-message' : 'default-sidebar-height'}} {{
    isShopBannerHidden() && !numberBundleSubscribed()
      ? 'shopBannerHidden' + (hasGlobalMessages() ? '-global-message' : '')
      : isShopBannerHidden() && numberBundleSubscribed()
        ? 'shopBannerHidden-' + numberBundleSubscribed() + '-bundle' + (hasGlobalMessages() ? '-global-message' : '')
        : 'fadeInDown_ShopContainer'}}">
  <div class="d-flex flex-column gap-4">
    @for (item of sidebar(); track itemIndex; let itemIndex = $index) {
    <!-- <details [open]="item.checked" name="subcategory"> -->
    <details [open]="item.checked" name="subcategory">
      <summary [ngClass]="{'h6-regular': !item.checked, 'h6-bold': item.checked}"
        (click)="goToShopSection(item, $event)">
        {{item.name}}
      </summary>

      @if (item.sublist && item.sublist.length) {
      <ul class="mt-2">
        @for (sublist of item.sublist; track $index) {
        <li class="h7-semibold" [class.subcategory-selected]="sublist.checked" (click)="goToShopSection(sublist)">
          {{sublist.name}}
        </li>


        @if (sublist.sublist && sublist.sublist.length) {
        <ul class="mt-2 ps-4">
          @for (sub_sublist of sublist.sublist; track $index) {
          <li class="h7-semibold" [class.subcategory-selected]="sub_sublist.checked"
            (click)="goToShopSection(sub_sublist)">
            {{sub_sublist.name}}
          </li>
          }
        </ul>
        }

        }
      </ul>
      }

    </details>
    }
  </div>
</nav>
