<section class="page-wrap animated fadeIn" >
  <div class="container">
    <div class="title is-center is-full-width px-5">
      <h1 class="title title__main is-center">FAQ’S</h1>
      <p class="text-center">Got questions about our The Produce Box products? You’re in the right place! Whether you’re curious about how we source our produce, delivery options, or how to store your veggies for maximum freshness, we’ve got the answers here. We’re committed to keeping things simple and transparent, so browse through our FAQs and feel free to reach out if you need anything else!</p>
    </div>
    <div ngbAccordion [closeOthers]="true">
      @for (item of faq; track item) {
        <div ngbAccordionItem [collapsed]="true">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>{{ item.question }}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div [innerHTML]="sanitizeHtml(item.answer)">
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  </div>
</section>

