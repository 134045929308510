<app-notification></app-notification>

@if (product()?.id) {
<div class="container-fluid main-container__full">
  <!-- Product details -->
  <div class="row">
    <!-- Product Images -->
    <div class="col-lg-3 animated fadeInLeft">
      <div class="product__img">
        <img [src]="productImages()[0]" alt="" class="img-fluid w-100" (error)="handleImageError($event)">
      </div>
    </div>


    <div class="col-md-6 col-lg-6 pe-5 animated fadeInDown">
      <!-- Product Info -->
      <div class="product__info">

        <!-- Title -->
        <h2 class="product__name">{{ selectedVariant()?.name || product()?.name }}</h2>

        <!-- Subtitle -->
        <h6 class="product__producer">{{ product()?.producer?.name }}</h6>

        <!-- Description -->
        <div class="product-desc-text" [innerHTML]="product()?.description?.short"></div>

        <!-- Tags Icons -->
        <ul class="list-group list-group-horizontal">
          @for (tag of product()?.tags || []; track $index) {
          <li class="list-group-item pt-0 ps-0 tag-container">
            <img src="{{tag.icon || 'assets/images/product-placeholder.webp'}}" [alt]="tag.name">
            {{tag.name}}
          </li>
          }
        </ul>
      </div>
    </div>
    <!-- Product Summary -->

    @let cardResume = productCardSummary();
    @if (cardResume){
    <div class="col-md-6 col-lg-3 animated fadeInRight">
      <div class="product__summary">
        <app-product-card-v2 [isGiftCard]="true" (selectedVariant)="selectedVariant.set($event)"
          [cardResume]="cardResume">
        </app-product-card-v2>
      </div>
    </div>
    }

  </div>

  <hr class="mt-4">

  <div class="row mt-4">
    <div class="detail">
      <div class="num">1</div>
      <div class="text d-grid">
        Who would you like to give this to?
        <small class="text-muted fst-italic">
          <b>NOTE:</b>
          this will generate an automated email. If you would like the gift card to be a surprise, please
          put
          your email in both the sender and recipient email field.</small>
      </div>
    </div>
  </div>

  <div class="row mt-4 animated fadeIn">
    <form action="" [formGroup]="giftCardForm">
      <div class="row row-gap-4">
        <!-- Sender's Name -->
        <div class="col-lg-6 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('senderName')" id="senderName" placeholder
              [readOnly]="hasSession()" formControlName="senderName">
            <label for="senderName">This gift is from</label>
            <div *ngIf="validateErrors('senderName')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('senderName')}}
            </div>
          </div>
        </div>
        <!-- Sender's Email -->
        <div class="col-lg-6 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('senderEmail')" id="senderEmail" placeholder
              [readOnly]="hasSession()" formControlName="senderEmail" (focusout)="validateEmailExistence()">
            <label for="senderEmail">Sender's Email</label>
            <div *ngIf="validateErrors('senderEmail')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('senderEmail')}}
            </div>
          </div>
        </div>
        <!-- Recipient's Name -->
        <div class="col-lg-6 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('recipientName')" id="recipientName"
              placeholder formControlName="recipientName">
            <label for="recipientName">This gift is to</label>
            <div *ngIf="validateErrors('recipientName')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('recipientName')}}
            </div>
          </div>
        </div>
        <!-- Recipient's Email -->
        <div class="col-lg-6 col-md-12">
          <div class="form-floating has-validation">
            <input class="form-control" [class.is-invalid]="validateErrors('recipientEmail')" id="recipientEmail"
              placeholder formControlName="recipientEmail">
            <label for="recipientEmail">Recipient's Email</label>
            <div *ngIf="validateErrors('recipientEmail')" class="invalid-feedback">
              <i class="fas fa-xmark"></i>
              {{validateErrors('recipientEmail')}}
            </div>
          </div>
        </div>

        <!-- Recipient's Address -->
        <div class="col-12">
          <app-autocomplete-input [label]="addressLabel"
            (validSelectedAddress)="recipientsAddress.set($event)"></app-autocomplete-input>
        </div>

      </div>
    </form>
  </div>

  <hr class="mt-4">

  <div class="row mt-4">
    <div class="detail">
      <div class="num">2</div>
      <div class="text">Add a Personal Message</div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="form-floating">
        <textarea class="form-control" [formControl]="personalMessage" placeholder="Leave a comment here"
          id="personalMessage"></textarea>
        <label for="personalMessage">Personal Message</label>
      </div>
    </div>
  </div>

  <div class="row mt-lg-4 mt-md-3">
    <section class="col-lg-3 col-md-4 col-12 mx-auto">
      <button class="btn btn-primary w-100" (click)="onPurchaseGiftCardClicked()">Purchase</button>
    </section>
  </div>

</div>


} @else {
<app-empty-message [message]="'No gift cards were found'"></app-empty-message>
}