<header class="blog-post__title animated fadeInDown">

  <span role="button" class="blog-post__title-back" (click)="goToBlogs()">
    <i class="fa-solid fa-arrow-left"></i>Back
  </span>

  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
        <div class="blog-post__title-content">

          <div class="blog-category">
            <span class="category" [ngStyle]="{'background-color': blogPostDetail()?.category?.color}">
              {{blogPostDetail()?.category?.name}}
            </span>
          </div>

          <h1 class="h1-semibold head-font">
            {{blogPostDetail()?.title}}
          </h1>

        </div>
      </div>

      <div class="col-xs-12 col-sm-5 d-block d-md-none">
        <img [src]="blogPostDetail()?.image || 'assets/images/product-placeholder.webp'" class="img"
          alt="Image of {{blogPostDetail()?.title}}" (error)="handleImageError($event)">
      </div>

      <div class="col-md-5 col-lg-5 d-none d-md-block">
        <div class="blog-post__img-wrap">
          <img [src]="blogPostDetail()?.image || 'assets/images/product-placeholder.webp'" class="blog-post__img"
            alt="Image of {{blogPostDetail()?.title}}" (error)="handleImageError($event)" />
        </div>
      </div>

    </div>
  </div>

</header>

<div class="clearfix"></div>

<div class="container">
  <div class="row">

    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 animated fadeInLeft">

      @if (blogPostDetail()?.author) {
      <span class="blog-post__date h7-regular">
        {{blogPostDetail()?.author}}
      </span>
      }

      <div class="blog-post__date">
        {{blogPostDetail()?.publishDate}}
      </div>

      @if (blogPostDetail()?.titleTags?.length) {
      <h5>Tags</h5>
      <div class="blog-post__tags mb-3">
        @for (tag of blogPostDetail()?.titleTags; track $index) {
        <span>{{tag.name}}</span>
        }
      </div>
      }

    </div>

    @defer (on viewport) {
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 animated fadeInRight">
      <p [innerHTML]="sanitizeHtml(blogPostDetail()?.content ?? '')"></p>
    </div>
    } @placeholder {
    <div></div>
    }

  </div>

  <!-- Featured products -->
  @defer (on viewport) {
  @if (blogPostDetail()?.featuredProducts?.length) {
  <hr class="mt-4">
  <section class="animated fadeInDown">
    <div class="row">
      <div class="col-12">
        <div class="title is-center is-full-width mt-5">
          <h2 class="title__main head-font">Featured Products</h2>
        </div>
      </div>
    </div>
    @if (blogPostDetail()?.addAllButton) {
    <div class="row mb-4 d-flex justify-content-center">
      <button type="button" class="btn btn-primary w-auto h6-semibold head-font" (click)="addAllProducts($event)">
        Add All Products
      </button>
    </div>
    }
    <div class="row">
      <div class="col-md-12">
        <app-carousel [items]="blogPostDetail()?.featuredProducts" #featuredProducts></app-carousel>
      </div>
    </div>
  </section>
  }
  } @placeholder {
  <section></section>
  }

  <!-- Similar posts -->
  @if (blogPostDetail()?.similarPost?.length) {
  <hr class="mt-4">
  <section>
    <div class="row">
      <div class="col-12">
        <div class="title is-center is-full-width mt-5">
          <h2 class="title__main head-font">Similar Post</h2>
        </div>
      </div>
    </div>
    <div class="row">
      @for (blog of blogPostDetail()?.similarPost; track $index) {
      <div class="col-sm-4 mt-2">
        <app-post [blog]="blog"></app-post>
      </div>
      }
    </div>
  </section>
  }
</div>