<nav
    class="navbar navbar-expand-lg navbar-expand-xl navbar-expand-xxl bg-white shop-filters-navbar d-none d-lg-block d-xl-block d-xxl-block">
    <div class="container-fluid shop-filters-navbar__container">
        <div class="collapse navbar-collapse offcanvas offcanvas-end shop-filters-navbar__container__offcanvas"
            id="navbarSupportedContent">
            <div class="offcanvas-body w-100 z-n1 align-items-center">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex gap-3">
                    @for (item of specialCategories(); track $index) {
                    <li class="nav-item">
                        <app-special-category-pill [item]="item"></app-special-category-pill>
                    </li>
                    }
                </ul>
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-flex gap-3">

                    @if (isShowingSearchbar()) {
                    <li class="nav-item">
                        <button class="btn btn-sm border-0 d-flex rounded-4 shadow-sm align-items-center gap-2"
                            (click)="toggleSearchBar()">
                            <span class="material-symbols-outlined text-secondary-800">
                                close
                            </span>
                            Clear Search Bar
                        </button>
                    </li>
                    } @else {
                    <li class="nav-item">
                        <button class="btn btn-sm border-0 d-flex rounded-4 shadow-sm align-items-center gap-2"
                            (click)="toggleSearchBar()">
                            <span class="material-symbols-outlined text-secondary-800">
                                search
                            </span>
                            Search
                        </button>
                    </li>
                    }

                    @for (featuredTag of featuredTags(); track $index) {
                    <li class="nav-item">
                        <button
                            class="btn btn-sm border-0 d-flex rounded-4 shadow-sm align-items-center gap-2 featured-tag"
                            [class.bg-primary]="featuredTag.isChecked()" (click)="featuredTagClicked(featuredTag)">
                            <img class="featured-tag__icon" [src]="featuredTag.icon" [alt]="featuredTag.name"
                                (error)="handleImageError($event)">
                            {{featuredTag.name}}
                        </button>
                    </li>
                    }

                    <li class="nav-item dropdown">
                        <app-tags-filter class="top-header"></app-tags-filter>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</nav>

<nav class="navbar navbar-expand bg-white shop-filters-navbar d-block d-xl-none d-xxl-none d-lg-none d-md-block d-sm-block">
    <div class="container-fluid shop-filters-navbar__container">
        <div class="w-100 align-items-center">
            <div class="collapse navbar-collapse shop-filters-navbar__container__offcanvas scroll-container">
                <ul class="navbar-nav me-auto d-flex gap-3 flex-row overflow-y-auto pb-0 scroll-content">
                    @for (item of specialCategories(); track $index) {
                    <li class="nav-item item">
                        <app-special-category-pill [item]="item"></app-special-category-pill>
                    </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</nav>