<section class="animated fadeIn welcome-component">

  <div class="welcome-component__container">

    <header class="welcome-component__header">

      <h1 class="title">
        Welcome
      </h1>

      <div class="d-flex flex-column gap-3">

        <span class="subtitle">
          SELECT A BOX
        </span>

        <p class="text-secondary-700">
          Simplify your shopping with a Produce Box subscription—fresh, local produce delivered weekly, bi-weekly, or
          monthly. Set it and forget it, or log in on the weekends to customize and select additional add ons from our
          store, like fresh baked bread or eggs.
        </p>

        <p class="text-secondary-700">
          A subscription box isn’t required to shop with us, but it’s the easiest way to stay stocked and support NC
          farmers. Pick your base box, customize to your liking, and get started today!
        </p>

      </div>

    </header>

    <!-- Products -->
    <section class="row welcome-component__products">

      @defer (on immediate; when products().length) {
      @if (products().length) {
      @for (product of products(); track product.id) {
      @defer (on viewport; prefetch on immediate) {
      <div class="col-xxl-3 col-lg-4 col-6 animated fadeIn">
        <div class="product-card-grid__item">
          <app-product-card-v2 [isFromWelcome]="true" [cardResume]="{  settings: {}, productDetails:  product}">
          </app-product-card-v2>
        </div>
      </div>
      } @placeholder {
      <div class="col-xxl-3 col-lg-4 col-6  animated fadeInUp fadeIn mb-3">
        <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
          <span class="placeholder bg-secondary col-12"></span>
          <span class="placeholder bg-secondary col-6"></span>
          <span class="placeholder bg-secondary col-6"></span>
          <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
          <div class="card-body">
            <h5 class="card-title placeholder-glow">
              <span class="placeholder bg-secondary col-6"></span>
            </h5>
            <p class="card-text placeholder-glow">
              <span class="placeholder bg-secondary col-7"></span>
              <span class="placeholder bg-secondary col-4"></span>
              <span class="placeholder bg-secondary col-4"></span>
              <span class="placeholder bg-secondary col-6"></span>
              <span class="placeholder bg-secondary col-8"></span>
            </p>
            <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
          </div>
        </div>
      </div>
      }
      }
      }
      }

    </section>

    <!-- Buttons -->
    <footer class="welcome-component__buttons">

      <button type="button" class="btn btn-outline-secondary" (click)="goToShop()">
        Explore Store
      </button>

    </footer>

  </div>

</section>