import { Component, inject, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import {NgForOf} from "@angular/common";

@Component({
    selector: 'app-delivery-areas',
    imports: [
        NgForOf
    ],
    templateUrl: './delivery-area.component.html',
    styleUrl: './delivery-area.component.scss'
})
export class DeliveryAreaComponent implements OnInit {
  private meta = inject(Meta);
  private title = inject(Title);


  locations = [
    {
      text: 'Raleigh',
      color: '#92519e'
    },
    {
      text: 'Durham',
      color: '#EFCF16'
    },
    {
      text: 'Chapel Hill',
      color: '#F17441'
    },
    {
      text: 'Wake Forest',
      color: '#3b4a91'
    },
    {
      text: 'Clayton',
      color: '#69975a'
    },
    {
      text: 'Garner',
      color: '#b15655'
    },
    {
      text: 'Fuquay-Varina',
      color: '#cd7f0e'
    },
    {
      text: 'Apex',
      color: '#c87877'
    },
    {
      text: 'Holly Springs',
      color: '#136e8e'
    },
    {
      text: 'Knightdale',
      color: '#92519e'
    },
    {
      text: 'Hillsborough',
      color: '#ba894b'
    }
  ];


  ngOnInit(): void {
    this.setUpSEOTags()
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/delivery-areas'
    const component = 'Delivery Areas'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}
