<!-- <div class="maintenance-container main-container__full">
    <div class="maintenance-content">
        <img src="/assets/images/logo.webp" alt="Maintenance Image" class="maintenance-image animated fadeInDown">
        <i class="my-5 fas fa-cog fa-5x cogs text-black-50 text-center animated fadeInDown"></i>
        <p class="text-black-50 mb-5 text-center fs-1 animated fadeInUp">{{title()}}</p>
        <p class="text-black-50 text-center fs-4 fw-light desc animated fadeInUp">{{message()}}</p>
    </div>
</div> -->

<div class="d-flex flex-column justify-content-center align-items-center vh-100 px-4">
    <img src="assets/images/logo.webp" alt="Company Logo" class="mb-4" style="width: 150px;" />
    <p class="text-center mt-3 mb-0 text-primary h3-semibold">{{title()}}</p>
    <p class="text-center mt-3 text-secondary h4-semibold mb-0">
        {{message()}}
    </p>
    <!-- <span class="material-icons md-48 text-primary loading-icon my-3">autorenew</span> -->
    <!-- <i class="my-5 fas fa-cog fa-5x cogs text-primary text-center animated fadeInDown"></i> -->
</div>

<!-- Footer "Powered by Navego" -->
<footer class="footer fixed-bottom text-center py-2">
    <small class="text-muted">
        Powered by <strong>Navego</strong>
    </small>
</footer>