import { Component, ElementRef, HostListener, OnInit, Signal, computed, inject, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProductCartPreview } from "../shared/product-card/product-cart-preview/product-cart-preview.component";
import { SignalsStoreService } from '../shared/signals-store.service';
import { CarouselComponent } from '../shared/carousel/carousel.component';
import { Fee, RELATED_ORDER_PRODUCTS } from '../shared/types/order.type';
import { OrderService } from '../shared/order.service';
import { ResolutionService } from '../shared/resolution.service';
import { DeliveriesService } from '../settings/account/deliveries/deliveries.service';
import { formatDateToReadableString } from '../shared/utils/formatting';
import { DeliveryInformation } from '../settings/account/deliveries/intarfaces';
import { environment } from '../../environments/environment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CalculationsService } from '../shared/calculations.service';
import { ModalContentService } from '../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../shared/constants/modal-content-types';
import { TipsDonationsService } from '../shared/tips-donations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-preview',
  templateUrl: './cart-preview.component.html',
  styleUrl: './cart-preview.component.scss',
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressBarModule,
    FormsModule,
    CommonModule,
    ProductCartPreview,
    CarouselComponent
  ]
})
export class CartPreviewComponent implements OnInit {
  private signalsStoreService = inject(SignalsStoreService);
  private orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);
  private deliveriesService = inject(DeliveriesService);
  #calculationsService = inject(CalculationsService);
  #eRef = inject(ElementRef);
  #router = inject(Router);
  #modalContentService = inject(ModalContentService);
  #tipsDonationsService = inject(TipsDonationsService);

  groupedProducts: Signal<any> = computed(() => this.groupProducts());
  odooOrderProducts: Signal<any> = computed(() => this.setUpOdooProducts());
  showCartPreview = this.signalsStoreService.showCartPreview;

  isContentLoaded: Signal<any> = computed(() => this.signalsStoreService.isContentLoaded());
  odooOrder: Signal<any | null> = computed(() => this.orderService.odooOrder());
  products: Signal<any | null> = computed(() => this.setUpProducts());
  firebaseOrder: Signal<any | null> = computed(() => this.signalsStoreService.firebaseOrder());
  isMobile = computed(() => this.resolutionService.isMobile());

  carouselBuyAgainProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.BUY_AGAIN, false));
  carouselFavoritesProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.FAVORITES, false));
  carouselSuggestedProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.SUGGESTED));

  //#region Properties

  showFreeDeliverySlider = signal(environment.config.flows.order.showFreeDeliverySlider);

  hasVoluntaryDonationFlow = signal(environment.config.flows.order.voluntaryDonationFlow);

  deliveryInfo = computed(() => this.#setUpDeliveryInfo());

  orderSubTotal = computed(() => this.#calculationsService.getOrderSubTotal(this.odooOrder(), this.firebaseOrder()) || 0);

  orderCoupons: Signal<number> = computed(() => this.#calculationsService.getOrderCoupons(this.odooOrder()?.paymentDetails?.coupons || []));

  orderDeliveryFee = computed(() => this.#calculationsService.getOrderDeliveryFee(this.odooOrder(), this.orderSubTotal(), this.products()));

  additionalFees: Signal<Fee[]> = computed(() => this.#calculationsService.calculateOrderAdditionalFees(this.odooOrder(), this.orderSubTotal()));

  additionalFeesTotal: Signal<number> = computed(() => this.#calculationsService.calculateOrderAdditionalFeesTotal(this.additionalFees()));

  orderTaxes = computed(() => this.#getOrderTaxes());

  orderCredits = computed(() => this.#getOrderCredits());

  orderTipAmount = computed(() => this.#getOrderTip());

  orderTotal = computed(() => this.#getTotalOrder());

  orderDonationAmountVoluntary = computed(() => this.#getOrderDonationVoluntaryAmount());

  percentMinSpendForFreeDelivery: Signal<number> = computed(() => this.#calculatePercentForFreeDelivery());

  minSpendForFreeDelivery: Signal<number> = computed(() => this.odooOrder()?.paymentDetails?.deliveryFee?.minSpend || 0);

  updateTip = ModalContentTypes.UPDATE_TIP;

  createTip = ModalContentTypes.CREATE_TIP;

  isOpenModal = signal(false);

  //#endregion

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.#eRef.nativeElement.contains(event.target) && event.target.getAttribute('data-nav') != 'toggleCardPreviewNav' && !this.isOpenModal()) {
      this.signalsStoreService.showCartPreview.set(false);
    }
  }

  ngOnInit(): void {
    if (this.isMobile())
      this.orderService.getOrder();
  }

  setUpOdooProducts() {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();
    if (!odooOrder || !odooOrder?.products)
      return null

    if (!odooOrder?.products.common?.length && !odooOrder?.products.subscription?.length)
      return null

    let odooCommonProducts: any[] = odooOrder?.products.common;
    let firebaseCommonProducts: any[] = firebaseOrder?.products?.common || [];
    if (odooCommonProducts.length && firebaseCommonProducts.length) {
      const firebaseCommonProductsVariantIds = firebaseCommonProducts.map(e => e.variantId ?? e.variant.variantId);
      odooCommonProducts = odooCommonProducts.filter(p => !firebaseCommonProductsVariantIds.includes(p.variantId))
    }

    let odooSubscriptionProducts: any[] = odooOrder?.products.subscription;
    let firebaseSubscriptionProducts: any[] = firebaseOrder?.products?.subscription || [];
    if (odooSubscriptionProducts.length && firebaseSubscriptionProducts.length) {
      const firebaseSubscriptionProductsVariantIds = firebaseSubscriptionProducts.map(e => e.variantId ?? e.variant.variantId);
      odooSubscriptionProducts = odooSubscriptionProducts.filter(p => !firebaseSubscriptionProductsVariantIds.includes(p.variantId))
    }

    return { common: (odooCommonProducts || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0)), subscription: (odooSubscriptionProducts || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0)) };
  }

  groupProducts() {
    const firebaseOrder = this.firebaseOrder();

    if (!firebaseOrder || !firebaseOrder?.products)
      return null

    if (!firebaseOrder?.products.common?.length && !firebaseOrder?.products.subscription?.length)
      return null

    const common = (firebaseOrder?.products?.common || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0))
    const subscription = (firebaseOrder?.products?.subscription || []).sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0))

    return { common, subscription };
  }


  hideShowCartPreview() {
    this.showCartPreview.set(false);
  }


  private setUpProducts(): any {
    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();

    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = [...fbCommonProducts, ...odooCommonProducts];
    const subscriptionProducts = [...fbSubscriptionProducts, ...odooSubscriptionProducts]

    return {
      common: commonProducts,
      subscription: subscriptionProducts,
    }
  }

  private setUpCarouselItems(orderProductType: string, isOrderSuggestedProduct: boolean = true) {
    const hiddenProducts: number[] = this.signalsStoreService.hiddenCarouselProducts();

    if (this.odooOrder()?.isSkipped || !this.isMobile()) return [];
    const selectedOrderProductType = Object.values(RELATED_ORDER_PRODUCTS).includes(orderProductType as RELATED_ORDER_PRODUCTS) ? orderProductType
      : RELATED_ORDER_PRODUCTS.SUGGESTED;

    if (!this.isContentLoaded || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
      if (!this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
        return []

    let items = this.odooOrder()?.relatedProducts?.[selectedOrderProductType];

    if (isOrderSuggestedProduct) {
      const allProductsId = [...this.products().common, ...this.products().subscription].map((product: any) => product.id)
      items = items.filter((product: any) => !allProductsId.includes(product.id));
    }

    if (hiddenProducts.length)
      items = items.filter((product: any) => hiddenProducts.indexOf(product.variantId) === -1)

    items = items.map((product: any) => {
      product.image = 'assets/images/logo.webp';
      product.name = product.name;
      if (typeof product?.price === 'number')
        product.price = product.price.toFixed(2);

      return ({
        content: {
          orderId: this.odooOrder()?.id,
          productDetails: product,
          settings: { isCardInOrderPage: true, hideWhenWasOOS: true }
        },
      })
    })

    return items
  }

  //#region Methods

  #setUpDeliveryInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null

    const { deliveryDate, cutoffDate, cutoffTime, deliveryWindow } = (this.deliveriesService.deliveryZoneInfo()?.order || this.deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryDateFormatted = formatDateToReadableString(deliveryDate)
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate)

    const deliveryPickupDateText = this.signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: ';

    return {
      deliveryWindow,
      deliveryDateText: `${this.odooOrder()?.isSkipped ? 'Next' : ''} ${deliveryPickupDateText}${deliveryDateFormatted.monthNumber}/${deliveryDateFormatted.dayNumber}/${deliveryDateFormatted.year}`,
      cutoffDateText: `${cutoffDateFormatted.dayName} ${cutoffDateFormatted.month} ${cutoffDateFormatted.day} at ${cutoffTime}`,
      thanksMessage: this.signalsStoreService.isAddressRequired() ? 'Get excited for your delivery, which will arrive on:' : 'Your order will be ready for pick-up on:'
    }
  }

  #calculatePercentForFreeDelivery() {
    const subtotal = this.orderSubTotal();
    const minSpend = this.minSpendForFreeDelivery();
    return (subtotal * 100) / minSpend;
  }

  #getOrderTaxes() {
    const products = this.#calculationsService.setUpOrderProducts(this.odooOrder(), this.firebaseOrder());
    return this.#calculationsService.getOrderTaxes(products, this.odooOrder(), this.orderDeliveryFee());
  }

  #getTotalOrder() {
    const data = {
      subTotal: this.orderSubTotal(),
      deliveryFee: this.orderDeliveryFee(),
      taxes: this.orderTaxes(),
      tipAmount: this.orderTipAmount(),
      donationAmountVoluntary: this.orderDonationAmountVoluntary(),
      creditsAmount: this.orderCredits(),
      coupons: (this.orderCoupons() * -1),
      includeCoupons: !this.checkPendingChanges(),
      seasonalDeposits: this.odooOrder()?.paymentDetails?.seasonalDeposits || 0,
      additionalFees: this.additionalFeesTotal()
    };

    return this.#calculationsService.getOrderTotal(data);
  }

  #getOrderTip() {
    const products = this.#calculationsService.setUpOrderProducts(this.odooOrder(), this.firebaseOrder());
    return this.#calculationsService.getTipAmount(this.odooOrder(), this.firebaseOrder(), products);
  }

  #getOrderCredits() {
    return this.odooOrder()?.paymentDetails?.credits || 0;
  }

  #getOrderDonationVoluntaryAmount() {
    const products = this.#calculationsService.setUpOrderProducts(this.odooOrder(), this.firebaseOrder());
    return this.#calculationsService.getDonationVoluntaryAmount(this.odooOrder(), this.firebaseOrder(), products);
  }

  goToMarket() {
    this.#router.navigate(['/shop']);
    this.signalsStoreService.showCartPreview.set(false);
  }

  openModalTip(action: string) {

    this.signalsStoreService.odooTipAmount.set(this.orderTipAmount() ?? 0);
    this.isOpenModal.set(true);

    this.#modalContentService
      .openModal(ModalContentTypes.TIP)
      .closed
      .subscribe((res) => {

        setTimeout(() => {
          this.isOpenModal.set(false);
        }, 500);

        if (!res?.amount) return;

        const payload = {
          amount: res?.amount,
          isRecurrent: res?.isRecurrent,
          applyToAllOrders: res?.applyToAllOrders
        }

        if (action === this.createTip) {
          this.#tipsDonationsService.create(payload, ModalContentTypes.TIP, false);
        } else {
          this.#tipsDonationsService.update(payload, ModalContentTypes.TIP, false);
        }
      })
  }

  deleteTip() {
    this.isOpenModal.set(true);

    if (this.odooOrder()?.paymentDetails?.tip?.isRecurrent) {
      this.#modalContentService
        .openModal(ModalContentTypes.DELETE_TIP_AND_DONATION)
        .closed
        .subscribe((res) => {

          setTimeout(() => {
            this.isOpenModal.set(false);
          }, 500);

          const applyToAllOrders = Number(res?.applyToAllOrders);
          this.#tipsDonationsService.delete(applyToAllOrders, ModalContentTypes.TIP, false);
        });
    } else {
      this.#tipsDonationsService.delete(0, ModalContentTypes.TIP);
    }
  }

  checkPendingChanges() {
    return !!(this.products() &&
      [...this.products()?.common, ...this.products()?.subscription].some(product => product.hasPendingChanges));
  }



  //#endregion
}
