import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  WritableSignal,
  afterNextRender,
  computed,
  inject,
  signal, ViewChild, OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { FooterComponent } from "../shared/footer/footer.component";
import { RouterLink } from "@angular/router";
import { FirebaseCrudService } from '../shared/firebase-crud.service';
import { ResolutionService } from '../shared/resolution.service';
import { SignalsStoreService } from '../shared/signals-store.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

import { gsap } from 'gsap'
import { ProducersService } from '../pages/producers/producers.service';
import { handleImageError } from '../shared/common/utils';


@Component({
    selector: 'app-home',
    imports: [CommonModule, FooterComponent, RouterLink],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    host: { 'class': 'home-page' }
})
export class HomeComponent implements OnInit, OnDestroy {

  private resolutionService = inject(ResolutionService);
  private producersService = inject(ProducersService);
  private signalsStoreService = inject(SignalsStoreService);
  private meta = inject(Meta);
  private title = inject(Title);
  private sanitizer = inject(DomSanitizer);
  handleImageError = handleImageError;

  hasSession = computed(() => this.signalsStoreService.hasSession());

  popularCategories = [
    {
      img: 'assets/images/landing/home/category/1.png',
      text: 'Fresh Produce',
      link: '/shop'
    },
    {
      img: 'assets/images/landing/home/category/2.png',
      text: 'Local Meat',
      link: '/shop'
    },
    {
      img: 'assets/images/landing/home/category/3.png',
      text: 'Bakery',
      link: '/shop'
    },
    {
      img: 'assets/images/landing/home/category/4.png',
      text: 'Dairy & Eggs',
      link: '/shop'
    },
    {
      img: 'assets/images/landing/home/category/5.png',
      text: 'Prepared Meals',
      link: '/shop'
    },
    {
      img: 'assets/images/landing/home/category/6.png',
      text: 'Produce Boxes',
      link: '/shop'
    },
  ]

  locations = [
    {
      text: '',
      color: '#92519e'
    },
    {
      text: 'Raleigh',
      color: '#92519e'
    },
    {
      text: 'Durham',
      color: '#EFCF16'
    },
    {
      text: 'Chapel Hill',
      color: '#F17441'
    },
    {
      text: 'Wake Forest',
      color: '#3b4a91'
    },
    {
      text: 'Apex',
      color: '#c87877'
    },
    {
      text: 'Clayton',
      color: '#69975a'
    },
    {
      text: 'Fuquay-Varina',
      color: '#92519e'
    },
    {
      text: 'Hillsborough',
      color: '#ba894b'
    }
  ];
  intervalId: any;
  @ViewChild('textAnime', { static: false }) textAnime!: ElementRef<HTMLHeadingElement>;

  sections: HTMLElement[] = [];

  videoUrl: WritableSignal<string> = signal('');

  isMobile = computed(() => this.resolutionService.isMobile())

  producersCards = computed(() => {

    const producers = this.producersService.producersSignal();

    if (!producers)
      return [];

    return producers
      .slice(0, 3)
      .map((p: any) => {
        const desc = (p.description || '');
        const truncatedDescription = this.truncateHtml(desc, 100, p.detailUrl);
        p.description = truncatedDescription;
        return p
      });
  });

  hasGlobalMessages = computed(() => !!this.signalsStoreService.globalMessages().length);

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit() {
    this.setUpSEOTags();
    // Retrieve all sections
    const sectionElements = this.elementRef.nativeElement.querySelectorAll('.has-animation');
    this.sections = Array.from(sectionElements);
    this.stopInterval();

    if (isPlatformBrowser(this.platformId))
      this.producersService.getAllProducers();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    // If interval is not already running, start it
    if (!this.intervalId) {
      this.startInterval();
    }
  }
  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    const touch = event.touches[0];
    if (touch) {
      if (!this.intervalId) {
        this.startInterval();
      }
    }
  }

  startInterval(): void {
    var item = 1;
    this.intervalId = setInterval(() => {
      this.textChange(item);
      item = item + 1;
      if (item == this.locations.length) item = 1;
    }, 1000); // Run every 1 second while mouse is moving
  }
  stopInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Stop interval
      this.intervalId = null;
    }
  }
  ngOnDestroy(): void {
    this.stopInterval();
  }
  textChange(item: number): void {
    gsap.to(this.textAnime.nativeElement, {
      innerHTML: this.locations[item].text,
      color: this.locations[item].color,
    });
  }



  @HostListener('scroll', ['$event'])
  onElementScroll(event: any) {
    const scrollPosition = event.target.scrollTop;
    const windowHeight = event.target.clientHeight;
    console.log(windowHeight);
    // Loop through each section
    this.sections.forEach(section => {
      const sectionOffset = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      // Check if the section is in the viewport
      if (scrollPosition >= sectionOffset - windowHeight && scrollPosition < sectionOffset + sectionHeight) {
        // Add a class to the section
        this.renderer.addClass(section, 'animated');
      } else {
        // Remove the class from the section
        this.renderer.removeClass(section, 'animated');
      }
    });
  }

  private setUpSEOTags() {

    const { mainTitle, mainDescription, baseKeywords: keywords, baseURL, type } = environment.config.seo;
    const url = 'home';

    // Page title
    this.title.setTitle(mainTitle);

    // Standart <meta> tag for SEO
    this.meta.addTag({ name: 'description', content: mainDescription });
    this.meta.addTag({ name: 'keywords', content: keywords.join(', ') });

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: mainTitle });
    this.meta.addTag({ property: 'og:description', content: mainDescription });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  truncateHtml(html: string, maxLength: number, url: string): SafeHtml {
    if (!isPlatformBrowser(this.platformId)) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let charCount = 0;
    const truncateNode = (node: Node): boolean => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        if (charCount + textContent.length > maxLength) {
          const truncatedText = textContent.slice(0, maxLength - charCount) + '... ';
          node.textContent = truncatedText;
          return true;
        } else {
          charCount += textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(node.childNodes);
        for (const childNode of childNodes) {
          if (truncateNode(childNode)) {
            return true;
          }
        }
      }
      return false;
    };

    truncateNode(tempDiv);

    let truncatedHtml = tempDiv.innerHTML;
    if (tempDiv.innerHTML.includes('</p>'))
      truncatedHtml = tempDiv.innerHTML.replace('</p>', `<a href="/pages/producers/${url}" class="fw-bold"> Read more</a></p>`);
    else
      truncatedHtml = tempDiv.innerHTML += `<a href="/pages/producers/${url}" class="fw-bold"> Read more</a></p>`;
    return this.sanitizer.bypassSecurityTrustHtml(truncatedHtml);
  }
}


