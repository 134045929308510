import { Component, computed, Inject, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { SidebarService } from './sidebar.service';
import { SidebarCheckList } from './sidebar.types';
import { ShopComponent } from '../../shop/shop.component';
import { CategorySelected } from '../types/common.types';
import { toObservable } from '@angular/core/rxjs-interop';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MenuService } from '../menu.service';
import { filter, tap } from 'rxjs';
import { ProductsService } from '../../product/products.service';
import { LocalStorageService } from '../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../constants/databases';


@Component({
    selector: 'app-sidebar',
    imports: [
        MatCheckboxModule,
        FormsModule,
        CommonModule
    ],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  //#region Injections
  sidebarService = inject(SidebarService);
  signalsStore = inject(SignalsStoreService);
  #menuService = inject(MenuService);
  #productsService = inject(ProductsService);
  #localStorageService = inject(LocalStorageService);
  //#endregion

  //#region Properties

  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);
  sidebar = computed(() => this.sidebarService.sidebar());
  isShopBannerHidden = computed(() => this.signalsStore.isShopBannerHidden());
  numberBundleSubscribed = computed(() => this.signalsStore.numberBundleSubscribed());
  //#endregion

  //#region Constructor

  constructor(public shopComponent: ShopComponent, @Inject(PLATFORM_ID) private platformId: any) {

    toObservable(this.signalsStore.categoryInViewPort)
      .subscribe(obs => this.#changeCategoryInViewPort(obs));
  }

  //#endregion

  //#region Methods

  ngOnInit(): void {
    this.#setUpMenu()

    this.sidebarService.categoryToNavigate$.pipe(
      filter(item => !!item),
      tap(item => this.#productsService.goToSection(item))
    ).subscribe();
  }

  #setUpMenu() {
    if (!isPlatformBrowser(this.platformId)) return;
    this.#menuService.fetchMenuCategories().subscribe({
      next: (res) => {
        this.sidebarService.setUpSidebarData({ sidebarData: res.data, isFirstTime: true });
        setTimeout(() => { this.#selectCategoryByName() }, 1000);
      }
    });
  }

  #selectCategoryByName() {
    const categoryName: string = this.#localStorageService.get(LOCALSTORAGE_KEYS.CATEGORY_TO_SELECT_ON_SHOP) ?? '';
    if (!categoryName)
      return;

    const index = this.sidebarService.sidebar().findIndex(x => x.name.toLowerCase() === categoryName.toLowerCase());
    if (index === -1)
      return;

    this.sidebarService.goToCategory(this.sidebarService.sidebar()[index]);
    this.#localStorageService.remove(LOCALSTORAGE_KEYS.CATEGORY_TO_SELECT_ON_SHOP);
  }

  #changeCategoryInViewPort(obj: CategorySelected | null) {

    const { categoryId, subCategoryId, subCategorySonId } = obj ?? {};

    if (!categoryId || !subCategoryId)
      return;

    // Search the father
    const indexFather = this.sidebarService
      .sidebar()
      .findIndex(x => x.id === categoryId);

    if (indexFather === -1)
      this.#selectCategory(subCategoryId);
    else
      this.#selectSubCategory(indexFather, subCategoryId, subCategorySonId);
  }

  #selectCategory(subCategoryId: number) {

    const category = this.sidebarService
      .sidebar()
      .find(x => x.id === subCategoryId);

    if (!category)
      return;

    this.#resetOpenDetails();
    category.checked = true;
  }

  #selectSubCategory(indexFather: number, subCategoryId: number, subCategorySonId?: number) {

    this.#resetOpenDetails();

    // Mark the father as checked

    this.sidebarService
      .sidebar()[indexFather]
      .checked = true;

    const child = this.sidebarService
      .sidebar()[indexFather]
      .sublist?.find(x => x.id === subCategoryId);

    if (child)
      child.checked = true;

    if (subCategoryId) {
      const grandChild = child?.sublist?.find(x => x.id === subCategorySonId);

      if (grandChild)
        grandChild.checked = true;
    }
  }

  #resetOpenDetails() {

    this.sidebarService
      .sidebar()
      .forEach(x => {
        x.checked = false;
        x.sublist?.forEach(y => {
          y.checked = false
          y.sublist?.forEach(z => z.checked = false);
        });
      })
  }

  goToShopSection(item: SidebarCheckList, event?: Event) {
    this.#productsService.goToSection(item, event);
  }

  //#endregion
}
