import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { CommonModule } from "@angular/common";
import { BlogService } from '../blog.service';
import { DetailBlogPost } from '../blog.types';
import { tap } from 'rxjs';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { PostComponent } from '../post/post.component';
import { environment } from '../../../../environments/environment';
import { formatDateToReadableString } from '../../../shared/utils/formatting';
import { Router } from '@angular/router';
import { ResolutionService } from '../../../shared/resolution.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { ModalContentData } from '../../../shared/modal-content/modal-content';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { CarouselComponent } from '../../../shared/carousel/carousel.component';

@Component({
  selector: 'app-blog-post',
  imports: [
    CommonModule,
    PostComponent,
    CarouselComponent
  ],
  templateUrl: './blog-post.component.html',
  styleUrl: './blog-post.component.scss'
})
export class BlogPostComponent {

  //#region Services

  #meta = inject(Meta);

  #title = inject(Title);

  #blogService = inject(BlogService);

  #sanitizer = inject(DomSanitizer);

  #route = inject(Router);

  #resolutionService = inject(ResolutionService);

  #signalsStoreService = inject(SignalsStoreService);

  #modalContentService = inject(ModalContentService);

  //#endregion

  //#region Properties

  blogPostDetail = signal<DetailBlogPost | null>(null);

  isMobile = computed(() => this.#resolutionService.isMobile());

  featuredProducts = viewChild<CarouselComponent>('featuredProducts');

  //#endregion

  //#region Constructor

  constructor() {
    this.#getBlogPostDetail();
  }

  //#endregion

  //#region Methods

  #getId() {
    const arr = this.#route.url.split('/');
    return arr.splice(arr.length - 2, arr.length).join('/');
  }

  #getBlogPostDetail() {
    this.blogPostDetail.set(null);
    this.#blogService
      .getById(this.#getId())
      .pipe(
        tap(response => {

          if (!response.data)
            return;

          this.blogPostDetail.set({
            ...response.data,
            featuredProducts: response.data.featuredProducts.map((x: any) => ({ content: { productDetails: x }, isFromBlog: true })),
            publishDate: formatDateToReadableString(response.data.publishDate).readableString
          });

          this.#setUpSEOTags();
        })
      )
      .subscribe();
  }

  sanitizeHtml(html: string): SafeHtml {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return this.#sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
  }

  #setUpSEOTags() {

    const { metaTitle, metaDescription, metaKeywords } = this.blogPostDetail()?.SEO ?? { metaTitle: '', metaDescription: '', metaKeywords: '' };

    // Page title
    this.#title.setTitle(metaTitle);

    // Standart <meta> tag for SEO
    this.#meta.addTag({ name: 'description', content: metaDescription });
    this.#meta.addTag({ name: 'keywords', content: (metaKeywords ?? '').replaceAll(' ', ', ') });

    // Open Graph for social networks:
    this.#meta.addTag({ property: 'og:title', content: metaTitle });
    this.#meta.addTag({ property: 'og:description', content: metaDescription });
    this.#meta.addTag({ property: 'og:url', content: `${environment.config.seo.baseURL}${this.blogPostDetail()?.url}` });
    this.#meta.addTag({ property: 'og:type', content: 'article' });
    this.#meta.addTag({ property: 'og:image', content: this.blogPostDetail()?.image || 'assets/images/product-placeholder.webp' });
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  goToBlogs() {
    this.#route.navigate(['/pages/blog']);
  }

  addAllProducts(event: any) {

    if (!this.#signalsStoreService.hasSession())
      this.#openModalCheckAddress();

    this.featuredProducts()?.addAllProducts(event);
  }

  #openModalCheckAddress() {

    const data: ModalContentData = {
      title: 'Choose your delivery preference',
      textContent:
        `Before you start shopping, let's make sure you're located near one of our delivery routes or pick up locations`,
      closeable: false
    };

    this.#modalContentService
      .openModal(ModalContentTypes.CHECK_ADDRESS, data, undefined);
  }

  //#endregion
}
