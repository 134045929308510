import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  computed,
  inject,
} from '@angular/core';
import { PagesService } from '../pages.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ProducersService } from './producers.service';
import { handleImageError } from '../../shared/common/utils';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-producers',
    imports: [RouterLink, CommonModule],
    templateUrl: './producers.component.html',
    styleUrl: './producers.component.scss'
})
export class ProducersComponent implements OnInit {
  producersService = inject(ProducersService);
  private meta = inject(Meta);
  private title = inject(Title);
  handleImageError = handleImageError

  pagesService = inject(PagesService);
  producersCards = computed(() => {
    const producers = this.producersService.producersSignal();
    if (!producers) return []
    return producers.map((p: any) => {
      const desc = (p.description || '');
      const truncatedDescription = this.truncateHtml(desc, 100, p.detailUrl);
      p.description = truncatedDescription;
      return p
    })
  });
  loadingProducers = computed(
    () => !!this.producersService.isLoadingProducers()
  );

  private sanitizer = inject(DomSanitizer);

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    this.setUpSEOTags();
    this.pagesService.restartSideMenu();
    if (isPlatformBrowser(this.platformId))
      this.producersService.getAllProducers();
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/producers'
    const component = 'Meet Our Producers'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  truncateHtml(html: string, maxLength: number, url: string): SafeHtml {
    if (!isPlatformBrowser(this.platformId)) return '';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let charCount = 0;
    const truncateNode = (node: Node): boolean => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        if (charCount + textContent.length > maxLength) {
          const truncatedText = textContent.slice(0, maxLength - charCount) + '... ';
          node.textContent = truncatedText;
          return true;
        } else {
          charCount += textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const childNodes = Array.from(node.childNodes);
        for (const childNode of childNodes) {
          if (truncateNode(childNode)) {
            return true;
          }
        }
      }
      return false;
    };

    truncateNode(tempDiv);

    let additionalText = `<a href="/pages/producers/${url}" class="fw-bold"> Read more</a></p>`;

    let truncatedHtml = tempDiv.innerHTML;
    if (tempDiv.innerHTML.includes('... </p>'))
      truncatedHtml = tempDiv.innerHTML.replace('... </p>', '... ' + additionalText);
    else
      truncatedHtml = tempDiv.innerHTML += additionalText;

    truncatedHtml = truncatedHtml.substring(0, truncatedHtml.indexOf(additionalText) + additionalText.length);
    truncatedHtml = truncatedHtml.replaceAll('<p>', '<p style="margin-bottom:0px;">');
    return this.sanitizer.bypassSecurityTrustHtml(truncatedHtml);
  }
}
