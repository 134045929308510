import { Component } from '@angular/core';
import {NgForOf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-our-membership',
    imports: [
        NgForOf,
        RouterLink
    ],
    templateUrl: './our-membership.component.html',
    styleUrl: './our-membership.component.scss'
})
export class OurMembershipComponent {

}
