import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-community-impact',
    imports: [
        RouterLink
    ],
    templateUrl: './community-impact.component.html',
    styleUrl: './community-impact.component.scss'
})
export class CommunityImpactComponent {

}
