<div>
  @for (bundle of bundles(); track bundle.id) {
  <section class="shop-bundle-list">

    <div class="d-flex align-items-center gap-3">

      <!-- Image -->
      <img [src]="bundle.image || 'assets/images/product-placeholder.webp'" (error)="handleImageError($event)"
        alt="Image of {{bundle.name}}" class="shop-bundle-list__img">

      <!-- Content -->
      <div class="shop-bundle-list__content">

        <div>

          <span class="title">
            Your curated box this week
          </span>

          <span class="title-frequency">
            Frequency: {{bundle.frequency}}
          </span>

          <div class="d-flex gap-3">
            <h3 class="name">{{bundle.name}}</h3>
            <h3 class="price">{{bundle.price | currency}}</h3>
          </div>

        </div>

        <!-- Frequency -->
        <div class="frequency">
          <span class="frequency__title">Frequency</span>
          <span class="h7-semibold text-secondary-700">
            {{bundle.frequency}}
          </span>
        </div>

      </div>

    </div>

    <!-- Actions -->
    <div class="shop-bundle-list__actions" (click)="goToCustomBox(bundle.id)">
      <span class="h7-regular text-secondary-400 text">Open box</span>
      <span class="material-symbols-outlined text-secondary-400">
        chevron_right
      </span>
    </div>

  </section>
  }
</div>
