<div class="layout-shop" [class.layout--sidebar-closed]="false">

  <!-- Sidebar -->
  @if(!isMobile()) {
  <div class="layout-shop__sidebar {{hasGlobalMessages() ? 'height-sidebar-global-message' : 'default-sidebar-height'}} {{
    isShopBannerHidden() && !numberBundleSubscribed()
      ? 'shopBannerHidden' + (hasGlobalMessages() ? '-global-message' : '')
      : isShopBannerHidden() && numberBundleSubscribed()
        ? 'shopBannerHidden-' + numberBundleSubscribed() + '-bundle' + (hasGlobalMessages() ? '-global-message' : '')
        : 'fadeInDown_ShopContainer'}}" [class.sidebar--closed]="false">
    <div class="overlay" (click)="toggleSidebar()"></div>
    <app-sidebar></app-sidebar>
  </div>
  }

  <!-- Main content -->
  <div class="layout-shop__main {{
    isShopBannerHidden() && !numberBundleSubscribed()
      ? 'shopBannerHidden' + (hasGlobalMessages() ? '-global-message' : '')
      : isShopBannerHidden() && numberBundleSubscribed()
        ? 'shopBannerHidden-' + numberBundleSubscribed() + '-bundle' + (hasGlobalMessages() ? '-global-message' : '')
        : 'fadeInDown_ShopContainer'}}" #layoutShopMain (scroll)="onScroll($event)" id="layoutShopMain">
    @if(!marketStatus().isOpen) {
    <div class="closed-market-container animated fadeIn">
      <div class="alert" role="alert">
        <span class="material-symbols-outlined mat-icon-fill icon">
          calendar_today
        </span>
        <div class="text-container">

          <p class="header">{{closedMarket().title}}</p>
          <p class="legend">{{closedMarket().legend}}</p>
        </div>
      </div>
    </div>
    }

    @if(isShowingShopFilters() && !isMobile()) {
    <div class="shop-filters" [class.mb-3]="isFilteringByTags()">
      <app-search-bar></app-search-bar>
    </div>
    }

    @if (isFilteringByTags()) {
    <div class="shop-tags-filter">
      <app-filtering-tags></app-filtering-tags>
    </div>
    }

    @if (isShowingShopFilters() || isFilteringByTags()) {
    <hr class="animated fadeIn">
    }

    @if (isContentLoading()) {
    <div class="px-3">
      <mat-progress-bar class="rounded layout-shop__progress-bar" mode="indeterminate"
        value="accent"></mat-progress-bar>
    </div>
    }

    <div class="container-fluid">

      @if (!isContentLoading() && isContentLoaded()) {
      @if (!productsSignal().length) {
      <app-empty-message [message]="emptyMessage" class="mt-4"></app-empty-message>
      }
      }

      <div class="row product-card-grid" id="scroll-to-top-ref"
        [class.padding-at-bottom-sm]="productsSignal().length <= 3">
        @defer (on viewport) {
        @if (productsSignal()) {

        @for (product of productsSignal(); track $index) {

        @if (product.showCategory) {
        <h2 class="category-name mb-2 mb-sm-4">
          {{ product.category.name }}
        </h2>
        }

        @if (product.showSubcategory) {
        @if (getSubCategoryBanner(product.category.id, product.subcategory.id)) {
        <img [src]="getSubCategoryBanner(product.category.id, product.subcategory.id)"
          alt="Banner of subcategory {{product.subcategory.name}}" class="img-fluid animated fadeInDown w-100 mb-4">
        } @else {
        <h3 class="subcategory-name mb-2 mb-sm-4" [attr.category-id]="product.category.id"
          [attr.sub-category-id]="product.subcategory.id" [attr.sub-category-son-id]="product?.subcategorySon?.id ?? -1"
          [id]="product.category.id + '-' + product.subcategory.id" #subcategory>
          {{ product.subcategory.name }}
        </h3>
        }
        }

        @if (product.showSubcategorySon) {
        <h4 class="subcategory-son-name mb-2 mb-sm-4" [attr.category-id]="product.category.id"
          [attr.sub-category-id]="product.subcategory.id" [attr.sub-category-son-id]="product.subcategorySon.id"
          [id]="product.category.id + '-' + product.subcategory.id + '-' + product.subcategorySon.id" #subcategorySon>
          {{ product.subcategory.name }}: <span class="text-secondary">{{ product.subcategorySon.name }}</span>
        </h4>
        }


        @defer (on viewport; prefetch on immediate) {
        <div class="col-xxl-3 col-lg-4 col-6 p-0" id="{{product.id}}" (mouseenter)="onProductCardHover(product.id)">
          <div class="product-card-grid__item">
            @let productDetails = this.productDetails().get(product.id);
            <app-product-card-v2 [cardResume]="{ product, settings: {}, productDetails:  productDetails}"
              (onComponentReady)="onProductCardReady(product.id)" [isFromShop]="!!productDetails">
            </app-product-card-v2>
          </div>
        </div>
        } @placeholder {
        <div class="col-xxl-3 col-lg-4 col-6 p-0" id="{{product.id}}">
          <div class="product-card-grid__item__placeholder">
          </div>
        </div>
        }

        }
        }

        }
        @placeholder {
        <div class="px-3">
          <mat-progress-bar class="rounded layout-shop__progress-bar" mode="indeterminate"
            value="accent"></mat-progress-bar>
        </div>
        }
      </div>
    </div>
  </div>
</div>