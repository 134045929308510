import { afterNextRender, Component, computed, inject, signal, Signal, WritableSignal } from '@angular/core';
import { SpecialCategoryPillComponent } from './special-category-pill/special-category-pill.component';
import { SignalsStoreService } from '../signals-store.service';
import { HeaderService } from '../header/header.service';
import { ProductsService } from '../../product/products.service';
import { Tag } from '../../product/product.types';
import { handleImageError } from '../common/utils';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { ResolutionService } from '../resolution.service';

@Component({
    selector: 'app-shop-header',
    imports: [
        SpecialCategoryPillComponent,
        TagsFilterComponent
    ],
    templateUrl: './shop-header.component.html',
    styleUrl: './shop-header.component.scss'
})
export class ShopHeaderComponent {

  #signalsStoreService = inject(SignalsStoreService);
  #headerService = inject(HeaderService);
  #productsService = inject(ProductsService);
  #resolutionService = inject(ResolutionService);

  isShowingSearchbar = computed(() => this.#headerService.displaySearchBar())

  specialCategories: WritableSignal<any[]> = signal([]);
  #menu$ = toObservable(this.#signalsStoreService.menuSignal);

  featuredTags = computed(() => this.#setUpFeaturedTags());
  isMobile = computed(() => this.#resolutionService.isMobile());

  handleImageError = handleImageError;

  constructor() {
    afterNextRender(() => {
      this.#menu$.pipe(tap(menu => this.#setUpSpecialCategories(menu))).subscribe();
    })
  }

  #setUpSpecialCategories(menu: any[]) {
    if (!menu.length) return;
    const borderClasses = ['border-tertiary', 'border-secondary', 'border-primary', 'border-tertiary', 'border-secondary', 'border-primary'];
    this.specialCategories.set(menu.filter(e => !!e.isSpecial).map((e, i) => { e.borderClass = borderClasses[i]; return e }));
  }

  toggleSearchBar() {
    const isShowing = this.isShowingSearchbar();
    if (isShowing)
      return this.#headerService.clearFilters(true);
    this.#headerService.updateSearchBarDisplay(!isShowing);
  }

  #setUpFeaturedTags() {
    return this.#productsService.tagsSignal()?.filter(tag => tag.isFeatured) ?? [];
  }

  featuredTagClicked(featuredTag: Tag) {
    featuredTag.isChecked.update(current => !current);
    this.#productsService.filterBySelectedTags();
  }

}
