<app-notification></app-notification>
<section class="page-wrap animated fadeIn">
  <div class="container">
    <h1 class="title title__main is-center">Contact Us</h1>
    <p class="text-center">If you have a specific question, please check our <a routerLink='/pages/faq'
        target="_self">FAQ’s</a> first to see if we've answered it there! Otherwise, for any other questions, comments,
      or feedback, we welcome the opportunity to talk with you about our program that delivers farm-grown fresh fruits
      and vegetables (and so much more!) to the doorsteps of homes and offices across North Carolina.</p>

    <div class="row mb-3">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">location_on</span>
              <div class="list-content">
                <h6>Location</h6>
                <p>1109 Agriculture St. Raleigh, NC 27603</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">mail</span>
              <div class="list-content">
                <h6>Email</h6>
                <p><a href="mailto:info@theproducebox.com">info&#64;theproducebox.com</a></p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ul-icon-list is-last has-no-circle">
              <span class="list-icon material-symbols-outlined">call</span>
              <div class="list-content">
                <h6>Phone</h6>
                <p>919-904-4722</p>
              </div>
            </div>
          </div>
        </div>
        <div class="map-wrap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.448523626611!2d-78.66143749999999!3d35.7650377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89acf57ebf2f5bc7%3A0x96806c0ba4711d96!2s1109%20Agriculture%20St%2C%20Raleigh%2C%20NC%2027603%2C%20USA!5e0!3m2!1sen!2snp!4v1729700029286!5m2!1sen!2snp"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div class="col-md-6 mt-3 mt-md-0">

        <form class="contact-form" (submit)="submitForm($event)">
          <p class="text-center text-sm-start">For any other comments, questions, and inquiries - please send us a
            message below.</p>
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="first-name" name="firstName" placeholder="First name"
                  [class.is-invalid]="firstNameValidation.error" [(ngModel)]="formData.firstName"
                  (input)="validateField('firstName', $event)" (blur)="validateField('firstName', $event)">
                <label for="first-name">First Name</label>
                <div *ngIf="firstNameValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ firstNameValidation.error }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="last-name" name="lastName" placeholder="Last name"
                  [class.is-invalid]="lastNameValidation.error" [(ngModel)]="formData.lastName"
                  (input)="validateField('lastName', $event)" (blur)="validateField('lastName', $event)">
                <label for="last-name">Last Name</label>
                <div *ngIf="lastNameValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ lastNameValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="email-address" name="email" placeholder="Email"
                  [class.is-invalid]="emailValidation.error" [(ngModel)]="formData.email"
                  (input)="validateField('email', $event)" (blur)="validateField('email', $event)">
                <label for="email-address">Email</label>
                <div *ngIf="emailValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ emailValidation.error }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" appOnlyNumber [maxlength]="10" class="form-control" id="phone-number"
                  name="phoneNumber" placeholder="Phone Number" [class.is-invalid]="phoneNumberValidation.error"
                  [(ngModel)]="formData.phoneNumber" (input)="validateField('phoneNumber', $event)"
                  (blur)="validateField('phoneNumber', $event)">
                <label for="phone-number">Phone Number</label>
                <div *ngIf="phoneNumberValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ phoneNumberValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="subject" name="subject" placeholder="Subject"
                  [class.is-invalid]="subjectValidation.error" [(ngModel)]="formData.subject"
                  (input)="validateField('subject', $event)" (blur)="validateField('subject', $event)">
                <label for="subject">Subject</label>
                <div *ngIf="subjectValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ subjectValidation.error }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <textarea class="form-control" id="message" name="message" placeholder="Message"
                  [class.is-invalid]="messageValidation.error" [(ngModel)]="formData.message"
                  (input)="validateField('message', $event)" (blur)="validateField('message', $event)"></textarea>
                <label for="message">Message</label>
                <div *ngIf="messageValidation.error" class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ messageValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button class="btn btn-static btn-secondary" [disabled]="isFormInvalid()"> Send Message</button>
          </div>
        </form>
      </div>
    </div>


  </div>
</section>