import { Component, computed, inject } from '@angular/core';
import { RouterLink } from "@angular/router";
import { SignalsStoreService } from '../signals-store.service';

@Component({
    selector: 'app-footer',
    imports: [RouterLink],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

  private signalsStoreService = inject(SignalsStoreService);

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  scrollToTop(): void {
    const divToScroll = document.getElementById('scroll-to-top-ref');
    if (divToScroll) {
      divToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }
}

