import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { HeaderService } from '../header.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterType, FilterTypes } from './search-bar.types';
import { CommonModule, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { ProductsService } from '../../../product/products.service';
import { FilterParams } from '../../../product/product.types';
import { mapToArray } from '../../common/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalsStoreService } from '../../signals-store.service';

@Component({
    selector: 'app-search-bar',
    imports: [
        FormsModule,
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
    ],
    templateUrl: './search-bar.component.html',
    styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  private headerService = inject(HeaderService);
  private productService = inject(ProductsService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private signalsStoreService = inject(SignalsStoreService);

  searchBarContent: WritableSignal<string> =
    this.headerService.searchBarContent;

  filters: WritableSignal<Map<string, { type: FilterTypes; value: string }>> =
    signal(new Map());

  filterTypes: Map<FilterTypes, WritableSignal<number>> = new Map();

  selectedIndex = 0; // Índice del elemento seleccionado


  ngOnInit(): void {
    this.setUpFilterTypesMap();
  }

  ngAfterViewInit(): void {
    if (this.signalsStoreService.filterByProducer()) {
      this.searchBarContent.set(this.signalsStoreService.filterByProducer());
      this.add(FilterTypes.producers);
    }

    if (this.searchInput && this.searchInput.nativeElement) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  onInputKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.selectedIndex = (this.selectedIndex + 1) % this.filterTypes.size;
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.selectedIndex = (this.selectedIndex - 1 + this.filterTypes.size) % this.filterTypes.size;
    } else if (event.key === 'Enter' && this.selectedIndex !== -1) {
      event.preventDefault();
      const selectedFilterType = Array.from(this.filterTypes.keys())[this.selectedIndex];
      this.onFilterTypeSelected(selectedFilterType);
    }
  }

  private setUpFilterTypesMap() {
    for (const type of Object.values(FilterTypes)) {
      const key: any = type;
      this.filterTypes.set(key, signal(0));
    }
  }

  inputSearchTypeHandler(event: any) {
    const value = event.target.value;
    if (!value || value.length < 3) return;
  }

  onFilterTypeSelected(filterType: FilterTypes) {
    const filterExists = this.filters().get(
      `${filterType}${this.searchBarContent()}`
    );
    if (filterExists) return;

    const current = this.filterTypes.get(filterType);
    if (current) {
      current.update((value: number) => value + 1);
      this.add(filterType);
    }
  }

  add(filterType: FilterTypes): void {
    const value = (this.searchBarContent() || '').trim();
    if (value) {
      this.filters().set(`${filterType}${value}`, { type: filterType, value });
      this.searchBarContent.set('');
      this.searchInput.nativeElement.focus();
      this.search();
    }
  }

  remove(key: string): void {
    if (key && this.filters().has(key)) {
      const type = this.filters().get(key)?.type;
      if (!type) return;

      this.filters().delete(key);

      const current = this.filterTypes.get(type);
      if (current) current.update((value: number) => value - 1);

      this.searchInput.nativeElement.focus();

      if (this.filters().size > 0) this.search();
      else this.headerService.clearFilters(false);
    }
  }

  search() {
    const filterParams: FilterParams[] = this.setUpFilterParams();

    // If the current loaded component is not _ShopComponent, should redirect the client to it:
    this.headerService.setFiltering(true);
    if (
      this.activatedRoute?.root?.firstChild?.component?.name !==
      '_ShopComponent'
    ) {
      this.router.navigateByUrl('/shop');
    }
    this.productService.getProductsByFilter(filterParams);
  }

  private setUpFilterParams(): FilterParams[] {
    const params: Map<FilterType, string[]> = new Map();
    this.filters().forEach(
      (value: { type: FilterTypes; value: string }, key: string) => {
        if (!params.has(value.type)) {
          params.set(value.type, [value.value]);
        } else {
          const current = params.get(value.type);
          if (current) {
            current.push(value.value);
            params.set(value.type, current);
          }
        }
      }
    );

    return Array.from(params.entries()).map(([type, values]) => ({
      type,
      values,
    }));
  }

  toggleSearchBar() {
    this.headerService.clearFilters();
  }
}
