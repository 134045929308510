import { Component, Inject, OnInit, PLATFORM_ID, WritableSignal, computed, inject, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { DeliveriesService } from './deliveries.service';
import { AccountService } from '../../../shared/account.service';
import { DeliveryOptionsResponseItem } from '../../../shared/types/account.types';
import { DeliveryInformation, DeliveryOptionsFormattedItem, UpdateDeliveryInfoPayload } from './intarfaces';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { tap } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';
import { OrderService } from '../../../shared/order.service';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { SettingsService } from '../../settings.service';
import { afterDeliveryUpdates } from '../../../shared/common/utils';

@Component({
    selector: 'app-deliveries',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatIcon,
        MatSelectModule,
        FormsModule,
        MatRadioModule,
        CommonModule,
        EmptyMessageComponent
    ],
    templateUrl: './deliveries.component.html',
    styleUrl: './deliveries.component.scss'
})
export class DeliveriesComponent implements OnInit {

  private deliveriesService = inject(DeliveriesService);
  private accountService = inject(AccountService);
  private signalsStoreService = inject(SignalsStoreService);
  #notificationService = inject(NotificationService);
  #orderService = inject(OrderService);
  #modalService = inject(ModalContentService);
  #settingsService = inject(SettingsService);

  isRequiredAddress = computed(() => this.signalsStoreService.isAddressRequired());

  deliveryZoneInfo = computed(() => this.deliveriesService.deliveryZoneInfo());

  selectedDeliveryDayAll: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  selectedDeliveryTimeWindowAll: WritableSignal<number | string | null> = signal(-1);
  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  selectedDeliveryTimeWindow: WritableSignal<number | string | null> = signal(-1);
  isSaveChangesDisabled: WritableSignal<boolean> = signal(true);
  shouldAffectCustomerInfo: boolean = false;
  isTemporaryRouteChange = signal(false);
  temporaryRouteChangeMessage = signal('');
  deliveryOptions: DeliveryOptionsFormattedItem[] = [];

  session = computed(() => this.signalsStoreService.sessionSignal());

  isWaitingForAPIResponse = computed(() => this.#settingsService.isWaitingForAPIResponse());
  submitButtonText = computed(() => this.#setUpSubmitButtonText())

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.deliveriesService.getDeliveryZoneInfo().pipe(
      tap((response) => {
        this.getDeliveryOptions(response.data);
        this.#validateTemporaryRoute(response.data);
      })
    ).subscribe();
  }

  #validateTemporaryRoute(deliveryInfo: DeliveryInformation) {
    const { temporaryChange } = deliveryInfo;
    this.isTemporaryRouteChange.set(!!temporaryChange?.active);

    if (this.isTemporaryRouteChange())
      this.temporaryRouteChangeMessage.set(temporaryChange?.message ?? '');
  }

  private getDeliveryOptions(deliveryInfo: DeliveryInformation) {
    this.accountService.getDeliveryOptions().subscribe({
      next: (options: DeliveryOptionsResponseItem[]) => {
        this.setUpDeliveryOptions(options, deliveryInfo);
      }
    });
  }

  private setUpDeliveryOptions(options: DeliveryOptionsResponseItem[], deliveryInfo: DeliveryInformation) {
    this.deliveryOptions = options;

    // Verify if the user has order delivery information:
    if (deliveryInfo.order?.deliveryDayId) {
      const item = this.deliveryOptions.find(d => d.id === deliveryInfo.order.deliveryDayId);
      if (item) {
        this.selectedDeliveryDay.set(item);
        if (item.allowTimeWindow && deliveryInfo.order.deliveryTimeWindowId) {
          const timeSlot = item.timeSet.find(t => t.id === deliveryInfo.order.deliveryTimeWindowId)
          if (timeSlot) {
            this.selectedDeliveryTimeWindow.set(timeSlot.id);
          }
        }
      }
    }

    // Verify if the user has delivery info:
    if (deliveryInfo.deliveryDayId) {
      const item = this.deliveryOptions.find(d => d.id === deliveryInfo.deliveryDayId);
      if (item) {
        this.selectedDeliveryDayAll.set(item);
        if (item.allowTimeWindow && deliveryInfo.deliveryTimeWindowId) {
          const timeSlot = item.timeSet.find(t => t.id === deliveryInfo.deliveryTimeWindowId);
          if (timeSlot) {
            this.selectedDeliveryTimeWindowAll.set(timeSlot.id);
          }
        }
      }
    }
  }

  private setUpSelectedOptions(options?: DeliveryOptionsResponseItem[]) {
    if (!options) options = this.deliveryOptions;
    const selectedDeliveryDay = options.find(e => e.id === this.deliveryZoneInfo()?.deliveryDayId);
    const selectedOrderDeliveryDay = options.find(e => e.id === this.deliveryZoneInfo()?.order?.deliveryDayId);
    if (selectedDeliveryDay) {
      this.selectedDeliveryDay.set(selectedOrderDeliveryDay || null);
      this.selectedDeliveryDayAll.set(selectedDeliveryDay)
      const selectedDeliveryWindow = selectedDeliveryDay.timeSet.find(e => e.id === this.deliveryZoneInfo()?.deliveryTimeWindowId);
      const selectedOrderDeliveryWindow = selectedDeliveryDay.timeSet.find(e => e.id === this.deliveryZoneInfo()?.order?.deliveryTimeWindowId);
      if (selectedDeliveryWindow?.id) {
        this.selectedDeliveryTimeWindow.set(selectedOrderDeliveryWindow?.id || null);
        this.selectedDeliveryTimeWindowAll.set(selectedDeliveryWindow?.id);
      }
    }
  }

  setSelectedDeliveryDayAll(event: MatSelectChange) {
    const option = this.deliveryOptions.find(o => o.id === event.value);
    if (option) {
      this.selectedDeliveryDayAll.set(option);
      this.selectedDeliveryTimeWindowAll.set(null);
      const disableButton = this.disableSaveChangesButtons();
      this.shouldAffectCustomerInfo = !disableButton;
      this.isSaveChangesDisabled.set(disableButton);
    }
  }

  setSelectedDeliveryTimeWindowAll(value: any) {
    this.selectedDeliveryTimeWindowAll.set(value);
    this.shouldAffectCustomerInfo = !this.disableSaveChangesButtons();
    const disableButton = this.disableSaveChangesButtons();
    this.shouldAffectCustomerInfo = !disableButton;
    this.isSaveChangesDisabled.set(disableButton);
  }

  setSelectedDeliveryDay(event: any, item: DeliveryOptionsFormattedItem) {
    if (item && event.target.value === 'on') {
      this.selectedDeliveryDay.set(item);
      this.selectedDeliveryTimeWindow.set(null)
      this.isSaveChangesDisabled.set(this.disableSaveChangesButtons());
    }
  }

  setSelectedDeliveryTimeWindow(event: any, timeWindowId: number | string | null) {
    if (timeWindowId && event.target.value === 'on') {
      this.selectedDeliveryTimeWindow.set(timeWindowId);
      this.isSaveChangesDisabled.set(this.disableSaveChangesButtons());
    }
  }

  updateDeliveryInfo() {

    if (this.isTemporaryRouteChange()) {
      return;
    }

    // if ((this.shouldAffectCustomerInfo && !this.selectedDeliveryTimeWindowAll()) || (!this.shouldAffectCustomerInfo && !this.selectedDeliveryTimeWindow())) {
    //   this.#notificationService.show({ text: 'You should select a valid time slot', type: 'warning' });
    //   return;
    // }

    const payload: UpdateDeliveryInfoPayload = {
      dayId: this.shouldAffectCustomerInfo ? this.selectedDeliveryDayAll()?.id || null : this.selectedDeliveryDay()?.id || null,
      timeWindowId: this.shouldAffectCustomerInfo ? this.selectedDeliveryTimeWindowAll() : this.selectedDeliveryTimeWindow(),
      shouldAffectCustomerInfo: this.shouldAffectCustomerInfo
    }

    this.deliveriesService.updateDeliveryInfo(payload).pipe(
      tap(() => {
        this.shouldAffectCustomerInfo = false;
        this.setUpSelectedOptions();
        this.isSaveChangesDisabled.set(true);

        afterDeliveryUpdates({
          orderCutoffExceeded: this.deliveryZoneInfo()?.orderCutoffExceeded ?? null,
          orderRescheduled: this.deliveryZoneInfo()?.orderRescheduled ?? null,
          deliveryZoneInfo: this.deliveryZoneInfo(),
          orderService: this.#orderService,
          modalService: this.#modalService,
          isUpdatingFromProfile: false
        });

      })
    ).subscribe();

  }

  disableSaveChangesButtons() {
    if (this.selectedDeliveryDayAll()?.id !== this.deliveryZoneInfo()?.deliveryDayId) return false;
    if (this.selectedDeliveryDay()?.id !== this.deliveryZoneInfo()?.order?.deliveryDayId) return false;
    if (this.selectedDeliveryTimeWindowAll() !== this.deliveryZoneInfo()?.deliveryTimeWindowId) return false;
    if (this.selectedDeliveryTimeWindow() !== this.deliveryZoneInfo()?.order?.deliveryTimeWindowId) return false;
    return true;
  }

  #setUpSubmitButtonText() {
    return this.isWaitingForAPIResponse() ? 'Updating...' : 'Save Changes'
  }

  selectFromParent(item: DeliveryOptionsFormattedItem) {
    document.getElementById(`firstDeliveryDate${item.id}`)?.click();
  }
}
