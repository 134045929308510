<div class="page-wrap animated fadeIn">
  <div class="container">
    <h1 class="title title__main is-center">About Us</h1>
    <div class="row gy-3">
      <div class="col-lg-8 col-md-6">
        <img src="assets/images/landing/about/main.png" alt="" class="img-fluid mb-5">
        <p>The Produce Box is an online market that sources the <strong>freshest, tastiest food from North
            Carolina farmers </strong>and makers and delivers it to your doorstep.</p>
        <p>From our warehouse at the State Farmers Market in Raleigh, we deliver the season's best to homes and offices
          in the Triangle. A resilient food system is only possible through building bridges between local eaters and
          farmers. That's what we do best.</p>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="sub-img-wrap">
          <img src="assets/images/landing/about/sub.png" class="img-fluid" alt="Convenient home delivery">
        </div>
      </div>
    </div>
  </div>

  <section class="section-banner has-padding-block-5">
    <div class="container">
      <h2 class="section-title text-center">Our mission</h2>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <p>As a family-owned company born and bred in North Carolina, <span class="text-secondary">we aspire to
              support our community from seed to table.</span> That means paying our producers a fair price for their
            food; connecting eaters to sustainable products; and supplying fresh fruits and vegetables to our food
            insecure neighbors. We provide a convenient, online shopping solution for people just like you to support
            local farmers and makers from the comfort of your home.</p>
        </div>
        <div class="col-sm-2"></div>
      </div>
    </div>
  </section>
  <br>
  <section class="has-padding-block-5 position-relative">
    <div class="bg-pattern bg-pattern-1 is-pink"></div>
    <div class="bg-pattern bg-pattern-2 is-blue"></div>
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <h1 class="text-secondary-light mb-3">
            <span>The Produce Box<sup>®</sup></span> supports over 100 farms and 90 artisan foodmakers across North
            Carolina.
          </h1>
          <h4 class="text-secondary-light">
            Our webstore provides a vital additional revenue stream for the small farmers and food businesses that make
            our local economy thrive. We take care of marketing, cold storage, and last-mile delivery so our partners
            don't have to.
          </h4>
        </div>
        <div class="col-sm-5 offset-sm-1">
          <img src="assets/images/landing/about/support.png" alt="" class="img-fluid mb-5">
          <p>Along with our online store, The Produce Box also sources and packs fresh food boxes for our non-profit
            partners across the Triangle. In 2023 alone, we packed over 26,000 boxes full of fresh fruits and vegetables
            for families who might otherwise not have access.</p>
        </div>
      </div>
    </div>
  </section>

</div>