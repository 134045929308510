<div class="container-fluid animated fadeIn">
  <form class="needs-validation">
    <div class="row form-row">
      <div class="col-md-6">
        <div class="form-floating has-validation">
          <input placeholder type="text" class="form-control"
            [class.is-invalid]="!personalInformationValidations.get('firstName')?.()?.valid" name="firstNameCtrl"
            [ngModel]="personalInformation()?.firstName" (blur)="validatePersonalInfoField('firstName', $event)"
            (ngModelChange)="
              updatePersonalInformation({ firstName: $event }, 'firstName')
            " />
          <label for="firstNameCtrl">First Name</label>
          <div *ngIf="!personalInformationValidations.get('firstName')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('firstName')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <input placeholder type="text" class="form-control" name="lastNameCtrl"
            [class.is-invalid]="!personalInformationValidations.get('lastName')?.()?.valid"
            [ngModel]="personalInformation()?.lastName" (blur)="validatePersonalInfoField('lastName', $event)"
            (ngModelChange)="
              updatePersonalInformation({ lastName: $event }, 'lastName')
            " />
          <label for="lastNameCtrl">Last Name</label>
          <div *ngIf="!personalInformationValidations.get('lastName')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('lastName')?.()?.error }}
          </div>
        </div>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6">
        <div class="form-floating">
          <input placeholder type="text" class="form-control" maxlength="10" name="phoneCtrl" appOnlyNumber
            [class.is-invalid]="!personalInformationValidations.get('phone')?.()?.valid"
            [ngModel]="personalInformation()?.phone" (blur)="validatePersonalInfoField('phone', $event)"
            (ngModelChange)="
              updatePersonalInformation({ phone: $event }, 'phone')
            " />
          <label for="phoneCtrl">Phone</label>
          <div *ngIf="!personalInformationValidations.get('phone')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('phone')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-form-field class="w-100">
            <mat-label>Birthday</mat-label>
            <input matInput [matDatepicker]="birthdayPicker" (dateChange)="onDateChange($event)">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthdayPicker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="!birthdayValid" class="feedback">
            <i class="fas fa-xmark"></i>
            You must be at least 15 years old.
          </div>
        </div>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6">
        <div class="form-floating">
          <input placeholder type="email" class="form-control" name="emailCtrl"
            [class.is-invalid]="!personalInformationValidations.get('email')?.()?.valid"
            [ngModel]="personalInformation()?.email" (blur)="validatePersonalInfoField('email', $event)"
            (ngModelChange)="
              updatePersonalInformation({ email: $event }, 'email')
            " />
          <label for="emailCtrl">Email</label>
          <div *ngIf="!personalInformationValidations.get('email')?.()?.valid" class="invalid-feedback">
            <i class="fas fa-xmark"></i>
            {{ personalInformationValidations.get('email')?.()?.error }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating has-validation">
          <mat-form-field subscriptSizing="dynamic" class="w-100">
            <mat-label>Password</mat-label>
            <input placeholder type="password" class="form-control form-control-password" matInput maxlength="30"
              name="passwordCtrl" [class.is-invalid]="!isPasswordValid1()" [ngModel]="personalInformation()?.password"
              (ngModelChange)="
                updatePersonalInformation({ password: $event }, '')
              " type="{{ hidePassword ? 'password' : 'text' }}" />
            <button mat-button matSuffix type="button" (click)="hidePassword = !hidePassword" class="eye-icon-button"
              [attr.aria-label]="'Toggle ' + (hidePassword ? 'visibility' : 'hide')">
              @if (hidePassword) {
              <span class="material-symbols-outlined text-muted">
                visibility
              </span>
              } @else {
              <span class="material-symbols-outlined text-muted">
                visibility_off
              </span>
              }
            </button>
          </mat-form-field>

          <div class="d-flex row row-cols-2" [ngClass]="
              isPasswordValid1() ? 'valid-feedback' : 'invalid-feedback'
            ">
            @for (condition of passwordConditions; track condition) {
            <div class="col fs-password" [ngClass]="condition.valid() ? 'text-success' : 'text-danger'">
              <i class="fas" [ngClass]="condition.valid() ? 'fa-check' : 'fa-xmark'"></i>
              {{ condition.label }}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[0]" (change)="updateCheckboxValue($event.checked, 0)">Receive SMS
            messages</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[3]" (change)="updateCheckboxValue($event.checked, 3)">Receive SMS
            Delivery Notifications</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[1]" (change)="updateCheckboxValue($event.checked, 1)">Receive
            information on our latest product offerings, events and local impact</mat-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-floating">
          <mat-checkbox [checked]="checkboxValues[2]" (change)="updateCheckboxValue($event.checked, 2)">I agree to the
            <span class="text-decoration-underline" (click)="openNewTab('/terms-and-conditions')">Terms &
              Conditions</span></mat-checkbox>
        </div>
      </div>
    </div>
    <div class="form-row-action">
      <div class="d-grid gap-2 col-2 mx-auto w-50">
        <button type="button" class="btn btn-secondary head-font"
          [disabled]="!isValidFormPersonalInformation() || !birthdayValid || !firstStep.completed || !checkboxValues[2] || creatingAccount()"
          (click)="continue()">
          {{creatingAccount() ? 'Loading...' : 'Continue'}}
        </button>
      </div>
    </div>
  </form>
</div>
